<template>
  <div class="fs-14">
    <div>
      <div class="sidebar__section padder">
        <p class="sidebar__analytics-label">
          {{ elementLabel }}
        </p>
        <p
          class="sidebar__analytics-value flex align-center"
          :title="selectedElement.value"
        >
          <span>
            {{ selectedElement.value }}
          </span>
          <gl-menu-item
            v-tooltip.top="'Copy address'"
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(selectedElement.value)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-tooltip.top="'Edit labels'"
            class="sidebar__to-labeling"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(selectedElement.value)"
          />
        </p>
      </div>
      <div class="stat">
        <div class="stat-tag full">
          <div class="stat-item full">
            <div class="key">
              Owner
            </div>
            <div class="value">
              <VueSkeletonLoader
                v-if="isAddressDataLoading"
                animation="wave"
                :color="'#bec3d8'"
                :height="20"
                :radius="'2'"
                type="rect"
              />
              <div v-else>
                <span>
                  {{ addressData.clusterData
                    && addressData.clusterData.owner
                    && addressData.clusterData.owner }}
                </span>
                <span
                  v-if="addressData
                    && addressData.owner
                    && addressData.clusterData
                    && addressData.clusterData.owner
                    && addressData.owner !== addressData.clusterData.owner"
                >,</span>
                <span v-if="addressData.owner !== addressData.clusterData.owner">
                  {{ addressData.owner }}
                </span>
                <span
                  v-if="!addressData.owner && !(addressData.clusterData
                    && addressData.clusterData.owner)"
                >
                  Not identified
                </span>
              </div>
            </div>
          </div>
          <!--          <div class="stat-item stat-item__half">-->
          <!--            <div class="key">-->
          <!--              Type-->
          <!--            </div>-->
          <!--            <div class="value">-->
          <!--              <VueSkeletonLoader-->
          <!--                v-if="isAddressDataLoading"-->
          <!--                animation="wave"-->
          <!--                :color="'#bec3d8'"-->
          <!--                :height="20"-->
          <!--                :radius="'2'"-->
          <!--                type="rect"-->
          <!--              />-->
          <!--              <div v-else>-->
          <!--                <GlTag-->
          <!--                  v-if="addressData.type && addressData.type.name"-->
          <!--                  class="mr-1 mb-1"-->
          <!--                  :score="addressData.type && addressData.type.score"-->
          <!--                  :tag="addressData.type && capitalizeFirstLetter(addressData.type.name)"-->
          <!--                  :value="addressData.type && addressData.type.name"-->
          <!--                />-->
          <!--                <span v-else>—</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="sidebar__section">
          <p class="sidebar__analytics-label">
            Tags
          </p>
          <VueSkeletonLoader
            v-if="isAddressDataLoading"
            animation="wave"
            :color="'#bec3d8'"
            :height="20"
            :radius="'2'"
            type="rect"
          />
          <div
            v-else-if="addressData && addressData.scoringList && addressData.scoringList.length > 0"
          >
            <GlTag
              v-for="(tag, index) in addressData.scoringList"
              :key="index"
              class="mr-1 mb-1"
              :class="{'hidden': index > 6}"
              :score="tag.score"
              :tag="capitalizeFirstLetter(tag.name)"
            />
            <div
              v-if="addressData.scoringList.length > 6"
              class="all-tags-btn"
              @click="changeTab('tags')"
            >
              All tags
            </div>
          </div>
          <div
            v-if="addressData && addressData.tags && addressData.tags.length === 0
              && !addressData.type && !addressData.clusterData.type"
          >
            --
          </div>
        </div>
      </div>
    </div>
    <div
      class="tabs"
      :class="{ 'tab-loading': isAddressDataLoading }"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <div
          v-if="tab.visible"
          class="tabs-item"
          :class="[{'tabs-item--active': activeTab === tab.name},
                   {'hatching': addressData.clusterData && !addressData.clusterData.cluster && tab.name === 'cluster'},
                   {'hatching': !addressData.txCount && !isAddressDataLoading},
                   {'hatching': !addressData.type
                     && addressData.tags
                     && addressData.tags.length === 0
                     && !addressData.clusterData.type
                     && !addressData.clusterData.tags
                     && tab.name === 'tags'}]"
          @click="changeTab(tab.name)"
        >
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="activeTab === 'source'"
        class="relative"
      >
        <div v-if="calcLoading">
          <gl-loader class="table__loader" />
        </div>
        <div
          v-else-if="allDataSource.length"
          class="mt-3"
        >
          <div class="px-3 flex align-baseline space-between">
            <h2 class="sidebar__title mb-2">
              Source of Funds
            </h2>
            <div class="flex fs-14 align-end mr-3">
              Amount
              <div class="px-1 switch__wrap">
                <input
                  id="switch"
                  v-model="test"
                  type="checkbox"
                  @click="trackBy()"
                >
                <label for="switch">Toggle</label>
              </div>
              %
            </div>
          </div>
          <div class="flex align-center justify-center relative">
            <gl-pie
              ref="pie"
              :center="['50%', '60%']"
              class="relative aml-detected-list"
              :data-source="pieData"
              :height="470"
              :width="'100%'"
              @click="handlePieClick"
              @mouseout="sectionData = null"
              @move="handlePieHover"
            >
              <template #tooltip>
                <Transition name="fade">
                  <div
                    v-if="sectionData && sectionData.owners"
                    id="sourcePie"
                    class="testPie"
                  >
                    <div
                      v-for="(owner, index) in sectionData.owners"
                      :key="index"
                      :class="{'mb-2': index < sectionData.owners.length - 1}"
                    >
                      <div class="flex align-center">
                        <div class="sub-types-amount">
                          <span>
                            {{ trackByField === 'share'
                              ? formatShare(pieData.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                              : formatBtcAmount(owner[trackByField]) }}
                          </span>
                        </div>
                        <div
                          v-tooltip.top="capitalizeFirstLetter(owner.name)"
                          class="ml-3 ellipsis flex flex-start flex flex-1 sub-types-owner"
                        >
                          {{ capitalizeFirstLetter(owner.name) }}
                        </div>
                        <!--                      <GlTag-->
                        <!--                        v-if="!owner.funds.isType"-->
                        <!--                        class="ml-4"-->
                        <!--                        :score="owner.funds.score"-->
                        <!--                        :tag="owner.funds.name"-->
                        <!--                      />-->
                      </div>
                    </div>
                  </div>
                </Transition>
              </template>
            </gl-pie>
            <div class="risk-score-wrap">
              <strong class="risk-score-label">
                Risk Score
              </strong>
              <div
                class="risk-score-value"
                :style="`color: ${findColorByTypeScore(totalFunds)}`"
              >
                {{ formatFunds(totalFunds, false) }}
              </div>
            </div>
          </div>
        </div>
        <div class="source__action-block">
          <div
            v-if="(addressIsOwnerByHightRisk
              || hasDirectlyMixing
              || Number(addressRiskPercent) > 0
              || addressAreUnidentified
              || hasTagMoreRiskPoint) && !calcLoading"
            class="mb-2"
          >
            <p class="sidebar__analytics-label">
              AML RISK DETECTED
            </p>
            <StatusBlock
              v-if="addressIsOwnerByHightRisk"
              class="mb-1"
              label="Address is owned by a high risk entity"
            />
            <StatusBlock
              v-if="hasDirectlyMixing"
              class="mb-1"
              label="Address has directly participated in mixing"
            />
            <StatusBlock
              v-if="Number(addressRiskPercent) > 0"
              class="mb-1"
              :label="`Address received ${formatShare(addressRiskPercent)} funds from risky sources`"
            />
            <StatusBlock
              v-if="addressAreUnidentified"
              class="mb-1"
              label="More than 75% of sources for the address are unidentified"
            />
            <StatusBlock
              v-if="hasTagMoreRiskPoint"
              class="mb-3"
              label="Address has directly participated in high risk activities"
            />
          </div>
          <div class="gl-form__actions">
            <button
              v-if="!addressData.inMonitoring"
              class="gl-button gl-button--light full-submit gl-form__button gl-button--padder mr-4"
              :disabled="calcLoading"
              @click="addToMonitoringList(addressData.address)"
            >
              Add to Monitoring
            </button>
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(addressData.address)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'transactions'">
        <div class="stat mt-3">
          <div class="stat-tag full">
            <InfoBlock
              label="Balance"
              :loading="isTxDataLoading"
              :value="txData
                && txData.balance
                && toComaSeparate(String(formatBtcAmount(txData.balance))) || '0'"
            />
            <InfoBlock
              label="Number of txs"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTx
                && toComaSeparate(String(txData.totalTx))"
            />
            <InfoBlock
              label="Total sent"
              :loading="isTxDataLoading"
              :value="txData
                && txData.amountSent
                && toComaSeparate(String(formatBtcAmount(txData.amountSent)))"
            />
            <InfoBlock
              label="total received"
              :loading="isTxDataLoading"
              :value="txData
                && txData.amountReceived
                && toComaSeparate(String(formatBtcAmount(txData.amountReceived)))"
            />
            <InfoBlock
              label="first seen"
              :loading="isTxStatsDataLoading"
              :value="firstSeen ? `${formatDate(firstSeen, 'dd.MM.yyyy HH:mm')}` : null"
            />
            <InfoBlock
              label="last seen"
              :loading="isTxStatsDataLoading"
              :value="lastSeen ? `${formatDate(lastSeen, 'dd.MM.yyyy HH:mm')}` : null"
            />
            <InfoBlock
              label="Total tx sent"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTxSent
                && toComaSeparate(String(txData.totalTxSent))"
            />
            <InfoBlock
              label="Total tx received"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTxReceived
                && toComaSeparate(String(txData.totalTxReceived))"
            />
          </div>
          <div class="column full">
            <h2 class="sidebar__title mb-2 mt-2">
              Transactions List
            </h2>
            <div class="flex space-between mb-2 align-center">
              <div class="sidebar__analytics-label">
                Filter by date & time
              </div>
              <gl-menu-item
                icon="delete-action"
                :icon-height="24"
                :icon-width="24"
                label="Clear all"
                @click="clearDate"
              />
            </div>
            <date-picker
              ref="datePicker"
              v-model="transactionsListDates"
              class="mb-2"
              placeholder="Select datetime range"
              range
              :show-time-panel="showTimeRangePanel"
              type="datetime"
              @clear="handleDateClose"
              @close="handleRangeClose"
            >
              <template v-slot:footer>
                <div class="flex space-between">
                  <div>
                    <button
                      class="mx-btn mx-btn-text link"
                      @click="toggleTimeRangePanel"
                    >
                      {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </div>
                  <div>
                    <button
                      class="mx-btn mx-btn-text cancel-date-range mr-2"
                      @click="handleDateClose"
                    >
                      Cancel
                    </button>
                    <button
                      class="mx-btn mx-btn-text apply-date-range"
                      @click="setDate(transactionsListDates)"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </template>
            </date-picker>
          </div>
          <o-table
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="pagination-table"
            :data="localData"
            hoverable
            :loading="transactionsListLoading"
            @check="addTransactionData"
            @check-all="addAllTransactionData"
          >
            <template
              v-if="transactionsListLoading"
              slot="loading"
              slot-scope="props"
            >
              <gl-loader class="table__loader" />
            </template>
            <template
              slot="empty"
              slot-scope="props"
            >
              <div
                v-if="!transactionsListLoading"
                class="flex justify-center full grey-text mt-3 mb-4"
              >
                Data is empty
              </div>
            </template>
            <o-table-column
              v-slot="props"
              field="date"
              label="Date"
              width="170"
            >
              <div>
                {{ formatDate(props.row.timestamp * 1000, 'yyyy-MM-dd HH:mm:ss') || '-' }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="transaction"
              label="transaction"
            >
              <div
                class="link"
                @click="openInNewTab(props.row.tx_hash)"
              >
                {{ trancateString(props.row.tx_hash, 10) }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="actions"
              label="Actions"
            >
              <div class="link">
                <AddressTxActions
                  :tx="props.row.tx_hash"
                />
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="amount"
              label="amount"
            >
              <div>
                <span
                  :class="[
                    {'squander': Math.sign(props.row.amount) === -1},
                    {'profit': Math.sign(props.row.amount) === 1},
                  ]"
                >
                  {{ toComaSeparate(formatBtcAmount(props.row.amount, false)) }}
                </span>
              </div>
            </o-table-column>
          </o-table>
          <div
            v-if="localData.length > 0"
            class="flex flex-end fullwidth pa-1"
          >
            <o-pagination
              v-if="localData.length"
              class="transactions-list-pagination"
              :current.sync="currentPage"
              order="right"
              :per-page="perPage"
              simple
              :total="total"
              @change="pageChange"
            >
              <o-pagination-button
                slot="previous"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page mr-2"
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>

              <o-pagination-button
                slot="next"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page"
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </o-pagination>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'cluster'">
        <div class="stat mt-3">
          <div class="stat-tag full">
            <InfoBlock
              label="Cluster id"
              :value="addressData.clusterData.cluster"
            />
            <InfoBlock
              label="Cluster size"
              :loading="isAddressDataLoading"
              :value="addressData
                && addressData.clusterData
                && addressData.clusterData.clusterSize
                && toComaSeparate(String(addressData.clusterData.clusterSize))"
            />
            <InfoBlock
              label="owner"
              :loading="isAddressDataLoading"
              :value="addressData
                && addressData.clusterData
                && addressData.clusterData.owner
                && String(addressData.clusterData.owner)"
            />
          </div>
        </div>
        <ClusterAddressesTable
          v-if="addressData.clusterData.cluster || addressData.cluster"
          :cluster="addressData.clusterData.cluster || addressData.cluster"
          @add-tx-data="$emit('add-tx-data', $event)"
        />
      </div>
      <div v-else-if="activeTab === 'tags'">
        <div class="flex px-3 mt-2 mb-2 relative">
          <div
            class="gl-rounded-btn mr-2"
            :class="{'gl-button--dark': !switchTags}"
            @click="switchTags = false"
          >
            Address tags
          </div>
          <div
            class="gl-rounded-btn"
            :class="{'gl-button--dark': switchTags}"
            @click="switchTags = true"
          >
            Cluster tags
          </div>
        </div>
        <AddressTagsTable
          v-if="!switchTags"
          :address="selectedElement.value"
          :data="addressData"
        />
        <ClusterTagsTable
          v-else
          :cluster="selectedElement.data.cluster && String(selectedElement.data.cluster)
            || selectedElement.data.addressData && selectedElement.data.addressData.cluster"
          :data="addressData.clusterData"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { formatFunds } from "@/utils/report-data-formatter";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";
import { featureAccess } from "@/utils/accesses";
// Libs
import _ from 'lodash';
import moment from "moment";
import { createPopper } from '@popperjs/core';
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex'
// Components
import GlTag from '@/components/gl-tag';
import DatePicker from 'vue2-datepicker';
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import GlRadio from '@/components/gl-radio-button';
import VueSkeletonLoader from 'skeleton-loader-vue';
import GlPie from '@/components/charts/gl-pie-chart';
import AddressTagsTable from './components/addressTagsTable';
import ClusterTagsTable from './components/clusterTagsTable';
import StatusBlock from '@/pages/report/components/StatusBlock';
import ClusterAddressesTable from './components/clusterAddressesTable';
import AddressTxActions from './components/addressTxActions';


export default {
  name: 'AddressInfo',
  components: {
    GlMenuItem,
    InfoBlock,
    DatePicker,
    GlRadio,
    GlLoader,
    ClusterAddressesTable,
    StatusBlock,
    GlTag,
    GlPie,
    VueSkeletonLoader,
    AddressTagsTable,
    ClusterTagsTable,
    AddressTxActions
  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      monitoringCalculateHandler: null,
      addressMonitoringPending: false,
      addressData: {},
      txData: {},
      sectionData: [],
      trackByField: 'share',
      viewDonat: true,
      test: true,
      switchTags: false,
      allDataSource: [],
      activeTab: 'transactions',
      tabs: [
        {
          title: 'Transactions',
          name: 'transactions',
          visible: true
        },
        {
          title: 'Source of Funds',
          name: 'source',
          visible: featureAccess('REPORT')
        },
        {
          title: 'Cluster',
          name: 'cluster',
          visible: true
        },
        {
          title: 'Tags & Metadata',
          name: 'tags',
          visible: true
        },
      ],
      pieData: [],
      firstSeen: null,
      lastSeen: null,
      startRangeDate: null,
      endRangeDate: null,
      currentDate: null,
      totalFunds: 0,
      sort: null,
      backward: false,
      forward: false,
      calcLoading: false,
      transactionsListLoading: false,
      isAddressDataLoading: false,
      isTxDataLoading: false,
      isTxStatsDataLoading: false,
      checkedRows: [],
      addressUnknownSources: [],
      preCheckedRows: [],
      localData: [],
      calcData: [],
      visualiseLimit: 100,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      totalTxs: 0,
      placement: 'top',
      transactionsListDates: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      RISK_POINT: 55,
      addressRiskPercent: 0
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    ...mapGetters('user', ['isAdmin']),
    elementLabel() {
      return (this.selectedElement && this.selectedElement.type || '').replace('tx', 'Transaction Hash')
    },
    addressIsOwnerByHightRisk() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
        || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
        || this.addressData.type && this.addressData.type.score >= this.RISK_POINT
        || (this.addressData.clusterData.type && this.addressData.clusterData.type.score >= this.RISK_POINT)
    },
    hasDirectlyMixing() {
      return (this.addressData.type && this.addressData.type.name === 'mixing')
        || (this.addressData.clusterData.type && this.addressData.clusterData.type.name === 'mixing')
        || (this.addressData.tags && this.addressData.tags.find(tag => tag.name === 'coin join participant'))
        || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.name === 'coin join participant'))
    },
    hasTagMoreRiskPoint() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
        || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources && this.addressUnknownSources.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  beforeDestroy() {
    this.$root.$off("open-node");
  },
  created() {
    this.$root.$on('open-node', this.setValue);
  },
  async mounted() {
    await this.loadAddressData();
    await this.loadAddressInfo();
    if (!this.selectedElement.addressListData) {
      this.loadAddressInfo();
    } else {
      this.isOpenTransactionInfo = true
      await this.setAddressListData()
      await this.checkingRows()
    }
    await this.loadTxData();
    await this.loadTxStats();
    this.preSelectElement()
  },
  methods: {
    formatShare,
    formatFunds,
    toComaSeparate,
    findColorByTypeScore,
    capitalizeFirstLetter,
    trancateString,
    countChange() {
      localStorage.setItem('transactions-list-per-page', this.perPage)
      this.currentPage = 1
      this.loadAddressInfo()
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    pageChange(event) {
      this.currentPage = event
      this.loadAddressInfo()
    },
    changeTab(name = 'transactions') {
      if (!this.addressData.txCount || (!this.addressData.clusterData.cluster && name === 'cluster') || (!this.addressData.type
            && this.addressData.tags && this.addressData.tags.length === 0
            && !this.addressData.clusterData.type
            && !this.addressData.clusterData.tags
            && name === 'tags')) return

      this.activeTab = name

      if (this.activeTab === 'source') {
        this.loadAddressData()
        this.loadCalculation()
      }
    },
    toggleTypeData(index) {
      this.allDataSource.forEach(item => item.isOpen = false)

      this.allDataSource[index].isOpen = true
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      if ($event.event.event.clientX) {
        this.sectionData = $event.data || null
      }
    },
    handlePieClick({data}) {
      const typeIndex = this.allDataSource.findIndex(item => item.type === data.type)
      this.toggleTypeData(typeIndex)
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.allDataSource = this.allDataSource.map(item => ({
        ...item,
        // value: item[this.trackByField],
        name: `${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)} ${this.capitalizeFirstLetter(item.funds.type)}`,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
      }))

      this.sortedPieData()
      // this.loadCalculation()
    },
    toLabeling(val) {
      const { href } = this.$router.resolve({ name: 'labeling', query: { address: val, tab: 'labeling', type: this.coinType }})

      window.open(href, '_blank')
    },
    sortedPieData() {
      this.pieData = _.cloneDeep(this.allDataSource)
      this.pieData = this.pieData.sort(({ funds: a }, { funds: b }) =>
          a.default - b.default || b.score - a.score
      )
    },
    calkDataFormatter() {
      this.addressUnknownSources = this.calcData.filter(source => source.listType === 'Unknown sources')

      const groupedByType = formatter(this.calcData, 'funds.type')

      this.addressRiskPercent = 0

      groupedByType.forEach(item => {
        if (item.funds.score >= 55) {
          this.addressRiskPercent += item.share
        }
      })

      this.allDataSource = groupedByType.map(item => ({
        ...item,
        owners: this.calcData
            .filter((v) => v.funds.type === item.funds.type)
            .map((v) => ({
              ...v,
              name: v.owner,
            })),
        funds: {
          ...item.funds,
          default: item.funds.default || false
        },
        key: item.entity,
        name: `${this.trackByField === 'share' ? `${formatShare(groupedByType.length === 1 ? 1 : item.share)}` : this.formatBtcAmount(item[this.trackByField], true, 3)} ${this.capitalizeFirstLetter(item.funds.type)}`,
        isOpen: false,
        value: item.share,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item[this.trackByField]) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
      }))

      this.combineSourcesOwners()
      this.sortedPieData()
    },
    combineSourcesOwners() {
      this.allDataSource.map((item) => {
        item.owners = formatter(item.owners, 'name')
      })
    },
    async updateData() {
      this.currentPage = 1
      this.perPage = 10
      this.currentDate = null;
      this.sort = null;
      this.changeTab();
      await this.loadAddressData();
      await this.loadTxData();
      if (!this.selectedElement.addressListData) {
        await this.loadAddressInfo();
      } else {
        await this.setAddressListData()
        await this.checkingRows()
      }
      await this.loadTxStats();
      this.allDataSource = []
      this.sortedPieData()
      this.calcLoading = false
      this.preSelectElement()
    },
    setValue() {
      this.$nextTick(() => {
        this.loadAddressInfo()
        this.checkingRows()
      })
    },
    preSelectElement() {
      if (this.selectedElement.value) {
        this.cytoscape.searchElement(this.selectedElement.value, false, true)
      }
    },
    formatDate,
    formatBtcAmount,
    ...mapActions('analytics', ['getAddressData', 'getNewAddressInfo', 'getTransactionData', 'getTxStats', 'calculationAddressRisk', 'getAddressBrowserList', 'getAddressBrowserDetails']),
    ...mapActions('monitoring', ['addToMonitoring', 'getAddressesMonitoring']),
    checkingRows() {
      this.localData.map((item) => {
        if (this.cytoscape.searchElements(item.tx_hash, false, false).length > 0) {
          this.checkedRows.push(item)
        }
      })
      this.preCheckedRows = this.checkedRows;
    },
    setAddressListData() {
      this.localData = this.selectedElement.addressListData.dataList
      this.forward = this.selectedElement.addressListData.forward
      this.backward = this.selectedElement.addressListData.backward
    },
    loadCalculation() {
      this.calcLoading = true
      this.calculationAddressRisk(this.selectedElement.value).then(({data, success}) => {
        if (success) {
          this.calcData = data.sources
          this.totalFunds = data.totalFunds

          this.calkDataFormatter()
          this.calcLoading = false
        } else {
          this.$toasted.global.error({message: `${data.message}`})
          this.calcLoading = false
        }
      }).catch(() => {
        this.calcLoading = false
      })
    },
    loadAddressData() {
      this.isAddressDataLoading = true
      this.getAddressData(this.selectedElement.value)
          .then(({data, success}) => {
            if (success) {
              this.addressData = {
                ...data,
                tags: data.tags && data.tags.length > 0 ? data.tags : [],
                scoringList: this.formattingScoringList(data) || []
              };
            } else {
              this.addressData = {}
            }
            this.isAddressDataLoading = false
          })
          .finally(() => {
            this.isAddressDataLoading = false
          })
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.clusterData && data.clusterData.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.clusterData.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      if (data.clusterData && data.clusterData.type) {
        SCORING_LIST = [...SCORING_LIST, data.clusterData.type]
      }

      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    loadTxStats() {
      this.isTxStatsDataLoading = true
      this.getTxStats(this.selectedElement.value).then(({data}) => {
        this.firstSeen = data.firstSeen ? data.firstSeen * 1000 : ''
        this.lastSeen = data.lastSeen ? data.lastSeen * 1000 : ''
      }).finally(() => {
        this.isTxStatsDataLoading = false
      })
    },
    loadAddressInfo() {
      this.transactionsListLoading = true
      this.getAddressBrowserList({
        address: this.selectedElement.value,
        startRangeDate: this.startRangeDate,
        endRangeDate: this.endRangeDate,
        currentDate: this.currentDate,
        page: this.currentPage,
        count: this.perPage,
        sort: this.sort,
      }).then(({data: {list, backward, forward, totalTxs}}) => {
        this.total = totalTxs
        this.localData = list
        this.backward = backward
        this.forward = forward
        this.localData.map((item) => {
          if (this.cytoscape.searchElements(item.tx_hash, false, false).length > 0) {
              if (this.cytoscape.cy.nodes(`#${this.selectedElement.value}`).connectedEdges().length
                  && this.cytoscape.cy.nodes(`#${this.selectedElement.value}`).connectedEdges().map(el => el.data()).find(tx => tx.txHash === item.tx_hash))
              {
                  this.checkedRows.push(item)
                  this.cytoscape.replaceTxHash(this.selectedElement.value, item.tx_hash , Math.sign(item.amount) !== -1 ? 'prev' : 'next')
              }
          }
        })
        this.preCheckedRows = this.checkedRows;
      }).finally(() => {
        this.transactionsListLoading = false
      })

      // this.getNewAddressInfo({
      //   address: this.selectedElement.value,
      //   startRangeDate: this.startRangeDate,
      //   endRangeDate: this.endRangeDate,
      //   currentDate: this.currentDate,
      //   page: this.currentPage,
      //   count: this.perPage,
      //   sort: this.sort,
      // }).then(({ data: { list, backward, forward, totalTxs } }) => {
      //   this.total = totalTxs
      //   this.localData = list
      //   this.backward = backward
      //   this.forward = forward
      //   this.localData.map((item) => {
      //     if (this.cytoscape.searchElements(item.tx_hash, false, false).length > 0) {
      //       this.checkedRows.push(item)
      //     }
      //   })
      //   this.preCheckedRows = this.checkedRows;
      // }).finally(() => {
      //   this.transactionsListLoading = false
      // })
    },
    toggleCalendarHandler() {
      this.$emit('toggle-calendar')
    },
    toSeconds(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).unix()
    },
    setDate(date) {
      const [from, to] = date

      if (from, to) {
        this.startRangeDate = this.toSeconds(from);
        this.endRangeDate = this.toSeconds(to);
        // this.currentDate = undefined;
        // this.sort = undefined;
        this.currentPage = 1
        this.$refs.datePicker.closePopup()
        this.loadAddressInfo();
      } else this.clearDate()
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.$refs.datePicker.closePopup()
      this.loadAddressInfo();
    },
    clearDate() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadAddressInfo();
    },

    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({state}) {
              component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    },
    handleDiffCheckedRows(checkedRows, currData) {
      const diffItem = _.difference(checkedRows, currData)
      if (diffItem.length > 0) {
        diffItem.forEach(ele => {
          this.cytoscape.searchAndHideElementsByTxHash(ele.tx_hash, ele)
        })
        this.$emit('search-and-hide-elements')
      }
    },
    addTransactionData(val = []) {
      const selectedEl = val[val.length - 1]

      if (val.length > this.checkedRows.length) {

        this.getAddressBrowserDetails({ hash: selectedEl.tx_hash, address: this.selectedElement.value }).then(({ data }) => {

          this.checkedRows = this.checkedRows.map(row => {
            return Object.assign(row, selectedEl.tx_hash === row.tx_hash ? data : {})
          })

          this.handleDiffCheckedRows(this.checkedRows, val)
          let bigData = []

          bigData.push(data.inputs, data.outputs)
          bigData = bigData.flat().length

          if (bigData <= this.visualiseLimit || (bigData > this.visualiseLimit && confirm(`Selected transaction has ${bigData} addresses. Continue?`))) {
            this.$emit('set-transaction', {
              data: [selectedEl],
              address: this.selectedElement.value
            })

            this.$nextTick(() => {
              this.cytoscape.updateData(selectedEl.tx_hash, {
                inputsPerPage: 5,
                outputsPerPage: 5,
                inputsSkip: 0,
                outputsSkip: 0,
              })
              this.checkedRows.forEach((el) => {
                this.cytoscape.replaceTxHash(this.selectedElement.value, el.tx_hash , Math.sign(el.amount) !== -1 ? 'prev' : 'next')
              })
            })
          } else {
            this.$nextTick(() => {
              if (this.checkedRows.length) {
                this.$nextTick(() => {
                  this.checkedRows = this.checkedRows.filter((row) => {
                    return row.tx_hash !== selectedEl.tx_hash
                  })
                })
              }
            })
          }
        })
      } else {
        this.handleDiffCheckedRows(this.checkedRows, val)
        const diffItem = _.difference(this.checkedRows, val)
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.setTxHash(this.selectedElement.value, ele.tx_hash, Math.sign(selectedEl.amount) !== -1 ? 'next' : 'prev')
          })
        }
      }
    },
    addAllTransactionData(val) {
      this.handleDiffCheckedRows(this.checkedRows, val)
      if (val.length > this.checkedRows.length) {
        this.localData.forEach((item) => {
          this.getAddressBrowserDetails({ hash: item.tx_hash, address: this.selectedElement.value }).then(({ data }) => {
            const foundData = Object.assign(item, data.tx_hash === item.tx_hash ? data : {})

            this.$emit('set-transaction', {
              data: foundData ? [foundData] : [],
              address: this.selectedElement.value
            })
          })
        })
      }
    },
    openInNewTab(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    loadTxData() {
      this.isTxDataLoading = true
      this.getTransactionData(this.selectedElement.value)
        .then(({ data }) => {
          this.txData = data;
        })
        .finally(() => {
          this.isTxDataLoading = false
        })
    },
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
    addToMonitoringList(address) {
      this.calcLoading = true
      this.addToMonitoring({
        address,
        checkLastTen: false,
        blockchain: this.coinData.label
      }).then(({ success, data }) => {
        if (success) {
          this.addressMonitoringPending = true
          this.$toasted.global.success({message: `Address added to monitoring`})
          this.getAddressesMonitoring({ skip: 0, count: 1, address})
              .then(() => {
                this.monitoringCalculateHandler = setTimeout(() => {
                  this.monitoringGettingInterval(address)
                }, 3000);
              })
          this.addressData.inMonitoring = true
        }
        else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      })
    },
    monitoringGettingInterval(address) {
      this.monitoringCalculateHandler = setTimeout(() => {
        this.getAddressesMonitoring({ skip: 0, count: 1, address})
            .then(({ data }) => {
              if (data.list.length && data.list[0].riskScore !== undefined) {
                this.calcLoading = false
                clearTimeout(this.monitoringCalculateHandler);
                this.loadCalculation()
              } else {
                this.monitoringGettingInterval(address)
              }
            })
      }, 3000)
    },
    toFullReport(address) {
      const { href } = this.$router.resolve({ name: 'report', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
  },
}
</script>

<style scoped>
.tabs {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid var(--pale-grey);
}

.tabs-item {
  display: inline-block;
  padding: 12px 24px;
  color: var(--dark-grey-6-e);
  position: relative;
  cursor: pointer;
}

.tabs-item--active {
  font-weight: 600;
  color: #1b1f3b;
}

.tabs-item--active::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--soft-blue);
  transition: all 0.5s;
}

.stat {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}
.change-date {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 24px;
  padding-right: 8px;
  font-size: 14px;
}
.value {
  font-weight: 500;
}
.full {
  width: 100%;
}
.value {
  font-weight: 500;
}

.sidebar__to-labeling {
  padding-bottom: 4px;
}
.profit {
  color: rgb(0, 135, 90);
  background: rgb(209, 240, 219);
  padding: 2px 4px;
  border-radius: 2px;
}
.squander {
  color: rgb(217, 59, 48);
  background: rgb(245, 217, 215);
  padding: 2px 4px;
  border-radius: 2px;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-grey-6-e);
  text-transform: uppercase;
  margin-bottom: 8px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 255px;
}

.risk-score-label {
  font-size: 14px;
}

.sub-types-owner {
  /*margin-right: 24px;*/
  text-align: left;
}


.types-list-wrap {
  border-top: 1px solid var(--dark-grey-d-3);
  padding-top: 8px;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.transactions-list {
  width: 160px;
}

.transactions-list .vs__dropdown-toggle {
  height: 40px !important;
  background: #fff;
  border: none;
}

.transactions-list-select__pagination {
  width: 150px;
  background: #fff !important;
}

.transactions-list-select__pagination .vs__dropdown-toggle{
  background: #fff !important;
  border: 1px solid #f5f5f5 !important;
  height: 35px;
}

.stat-item__half {
  width: 50%;
}

.hidden__tags {
  display: none;
}

.all-tags-btn {
  border: 1px solid var(--soft-blue);
  display: inline-block;
  color: var(--soft-blue);
  padding: 2px 5px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.testPie {
  /*min-width: 400px;*/
  width: auto;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.sub-types-amount {
  white-space: nowrap;
  display: block;
  min-width: 140px;
}

.switch__wrap input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch__wrap label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 10px;
  background: #3030D628;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch__wrap label:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: var(--reflex-bluet);
  border-radius: 90px;
  transition: 0.3s;
}

.switch__wrap input:checked + label {
  background: #3030D628;
}

.switch__wrap input:checked + label:after {
  left: 100%;
  transform: translateX(-100%);
}

.switch__wrap label:active:after {
  width: 50px;
}

.switch__wrap {
  margin-bottom: 3px;
}

.hatching {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 49.9%, var(--dark-grey-d-3) 49.9%, var(--dark-grey-d-3) 60%, rgba(0, 0, 0, 0) 80%) fixed, linear-gradient(-45deg, var(--dark-grey-d-3) 16%, rgba(0, 0, 0, 0) 25%) fixed;
  background-size: 0.5em 0.5em;
  opacity: 0.6;
}

.source__action-block {
  position: fixed;
  bottom: 0;
  width: 640px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 12px 24px 24px;
  background: var(--white);
}

.aml-detected-list {
  margin-bottom: 100px;
}

@keyframes skeleton-animation
{

  0%
  {
    right: 100%;
  }

  50%
  {
    right: 0;
  }

  100%
  {
    right: 100%;
  }

}

.tab-loading:before
{
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 128px;
  height: 40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
  content: "";
  animation: skeleton-animation 1.25s infinite;
}

</style>
