<template>
  <div class="cytoscape-wrapper">
    <div
      id="cytoscape-container"
      ref="cytoscape-container"
    />
    <slot />
  </div>
</template>

<script>
// Libs
import cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import { config } from '@/config/cytoscape'

export default {
  name: 'CytoscapeContainer',
  data() {
    return {
      supportedEvents: ['vclick', 'cxttapend', 'grab', 'dragfree', 'mouseover', 'tap'],
    }
  },
  created() {
    cytoscape.use(cola)
  },
  mounted() {
    let previousTapStamp;
    const doubleClickDelayMs = 500;
    const cy = cytoscape({ container: this.$refs['cytoscape-container'], ...config })
    this.supportedEvents.forEach(type =>
        cy.on(type, e => this.$emit(type, e)),
    )

    this.checkDoubleTap(cy, previousTapStamp, doubleClickDelayMs);

    this.$emit('ready', cy)
  },
  beforeDestroy() {
    this.$emit('destroy')
  },
  methods: {
    checkDoubleTap(cy, previousTapStamp, doubleClickDelayMs) {
      cy.on('tap', function(e) {
        let currentTapStamp = e.originalEvent.timeStamp;
        let msFromLastTap = currentTapStamp - previousTapStamp;

        if (msFromLastTap < doubleClickDelayMs) {
          e.target.trigger('doubleTap', e);
        }
        previousTapStamp = currentTapStamp;
      });

      cy.on('doubleTap', (originalTapEvent, e) => {
        this.$emit('doubleTap', originalTapEvent, e)
      });
    }
  },
}
</script>
<style>
.cytoscape-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

#cytoscape-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
