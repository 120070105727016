<template>
  <div class="toolbar flex align-center">
    <div class="flex wrap">
      <div class="flex">
<!--        <vSelect-->
<!--          v-if="hasMultyCoin"-->
<!--          v-model="coin"-->
<!--          class="coin-select mr-2"-->
<!--          :clearable="false"-->
<!--          label="label"-->
<!--          :options="currencyList"-->
<!--          @change="handleCoinSelect"-->
<!--        />-->
        <GlCoinSelect @change="handleCoinSelect" small />
        <gl-search-box
          v-model="search"
          class="search-box mr-2 mb-2"
          dark-clear
          min-width="450px"
          placeholder="Add wallet address or transaction"
          @search="searching"
        />
      </div>
      <div class="dropdowns__wrap mr-2 mb-2">
        <gl-menu-dropdown
          icon-after="arrow-down"
          :icon-height="24"
          :icon-width="24"
          label="View"
          padder
          @toggle="handleToggleViewMenu"
        >
          <div
            v-if="!$can('use', 'eth')"
            class="label mb-3"
          >
            View mode
          </div>
          <gl-radio
            v-if="!$can('use', 'eth')"
            v-model="viewMode"
            label="Address view"
            value="address"
            @change="changeViewMode"
          />
          <gl-radio
            v-if="!$can('use', 'eth')"
            v-model="viewMode"
            label="Cluster view"
            value="cluster"
            @change="changeViewMode"
          />
          <hr v-if="!$can('use', 'eth')">
          <div
            class="label mb-3"
          >
            Address labels
          </div>
          <gl-checkbox
            v-if="$can('use', 'btc')"
            v-model="label"
            label="Cluster / Owner"
            @input="changeNodeLabels"
          />
          <gl-checkbox
            v-if="$can('use', 'eth')"
            v-model="label"
            label="Owner"
            @input="changeNodeLabels"
          />
          <gl-checkbox
            v-model="address"
            label="Address"
            @input="changeNodeLabels"
          />
          <hr v-if="!$can('use', 'eth')">
          <div class="label mb-3">
            Transaction labels
          </div>
          <gl-checkbox
            v-model="amount"
            label="Amounts"
            @input="changeEdgeLabels"
          />
          <gl-checkbox
            v-model="hash"
            label="Hash"
            @input="changeEdgeLabels"
          />
        </gl-menu-dropdown>
        <gl-menu-dropdown
          ref="casesDropdown"
          icon-after="arrow-down"
          :icon-height="24"
          :icon-width="24"
          label="Case"
        >
          <gl-menu-item
            icon="import"
            :icon-height="24"
            :icon-width="24"
            label="Import"
            @click="openFileInput"
          />
          <div>
            <ul id="demo">
              <TreeItem
                class="item"
                :disabled="isUnknowMode"
                :item="exportList"
                @edge="exports($event)"
              />
            </ul>
          </div>
          <gl-menu-item
            :disabled="isUnknowMode"
            icon="save"
            :icon-height="24"
            :icon-width="24"
            label="Save Case"
            @click="exportCaseHandler('cases')"
          />
          <gl-menu-item
            :disabled="isUnknowMode || !caseData"
            icon="save-as"
            :icon-height="24"
            :icon-width="24"
            label="Save Case as"
            @click="saveAs"
          />
        </gl-menu-dropdown>
      </div>
    </div>
    <div
      v-if="caseData"
      class="flex align-center mb-2 mr-2 ellipsis"
    >
      <gl-icon
        class="mr-1"
        :height="24"
        name="cases"
        :width="24"
      />
      <div
        class="ellipsis"
        style="max-width: 250px"
      >
        Case: {{ caseData.title }}
      </div>
    </div>
    <div
      v-tooltip="'Save'"
      class="pointer"
      @click="save"
    >
      <gl-icon
        v-if="caseData"
        class="mr-1"
        :height="24"
        name="save"
        :width="24"
      />
    </div>
    <div
      v-tooltip="'Save as'"
      class="pointer"
      @click="saveAs"
    >
      <gl-icon
        v-if="caseData"
        class="mr-1"
        :height="24"
        name="save-as"
        :width="24"
      />
    </div>

    <input
      ref="file"
      accept=".json"
      :style="{display: 'none'}"
      type="file"
      @change="onFileChange"
    >
  </div>
</template>

<script>
// Components
import GlCheckbox from "@/components/gl-checkbox";
import GlMenuDropdown from "@/components/gl-menu-dropdown";
import GlMenuItem from "@/components/gl-menu-item";
import GlIcon from "@/components/gl-icon";
import GlRadio from "@/components/gl-radio-button";
import TreeItem from "@/components/gl-tree-item";
import GlSearchBox from "@/components/gl-search-box";
import vSelect from 'vue-select';
import GlCoinSelect from "@/components/gl-coin-select.vue";
// Vuex
import { mapMutations, mapState } from "vuex";
import { validate } from "vee-validate";
import config from "@/utils/appConfig";

export default {
  components: {
    GlCheckbox,
    GlIcon,
    GlSearchBox,
    TreeItem,
    GlMenuDropdown,
    GlCoinSelect,
    GlMenuItem,
    vSelect,
    GlRadio,
  },
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    showCyto: {
      type: Boolean,
      default: false,
    },
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    caseData: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      label: true,
      address: false,
      amount: false,
      hash: false,
      calendarOpen: false,
      viewMode: 'address',
      search: '',
      coin: {
        key: 'btc',
        path: 'tx',
        label: 'BTC'
      },
      exportList: {
        label: "Export",
        icon: 'export',
        iconHeight: 24,
        iconWidth: 24,
        hasParent: false,
        disabled: this.isUnknowMode,
        children: [
          { name: "png", label: 'Export as PNG', icon: "png", hasParent: true, },
          { name: "csv", label: 'Export as CSV', icon: "png", hasParent: true, },
          { name: "json", label: 'Export as JSON', icon: "json", hasParent: true, },
        ]
      }
    }
  },
  computed: {
    ...mapState('analytics', ['isHash', 'isAddress', 'canUndo', 'canRedo', 'coinType', 'searchType', 'currencyList', 'coinData']),
    isUnknowMode() {
      return !this.isHash && !this.isAddress
    },
    hasMultyCoin() {
      return config.VUE_APP_COIN_TYPE
    },
    modeExplain() {
      if (this.isAddress) {
        return 'Search for a wallet address and click on the address nodes to investigate all connected transactions'
      } else if (this.isHash) {
        return 'Search for a TX hash and click on the input/output address node to reveal the prev/next spend'
      }

      return 'Indicator of the node click-to-explore mode. Search for a transaction ID so by clicking on the node, you reveal the next spend of the output. Search for a wallet address to investigate all of its transactions.'
    },
    getModeLabel() {
      if (this.isAddress) {
        return 'Exploration'
      } else if (this.isHash) {
        return 'Tracing'
      }

      return 'Unknown'
    },
  },
  watch: {
    coinType: {
      handler(val) {
        const currency = this.currencyList.find(el => el.key === val)

        if (currency) {
          this.coin = currency
          this.SET_COIN_TYPE(currency.key)
          this.SET_COIN_DATA(currency)
        } else {
          this.SET_COIN_TYPE('tx')
          this.coin = {
            key: 'btc',
            path: 'tx',
            label: 'BTC'
          }
        }
      },
      immediate: true
    },
    $route: {
      async handler(val, from) {
        if ((!val || !val.name || val.name === 'analytics') && (!from || !from.name || from.name === 'analytics')) {
          if (val.query.type) {
            const currency = this.currencyList.find(el => el.key === val.query.type)

            if (currency) {
              this.coin = currency
              this.SET_COIN_TYPE(currency.key)
              this.SET_COIN_DATA(currency)
            } else {
              this.SET_COIN_TYPE('tx')
              this.coin = {
                key: 'btc',
                path: 'tx',
                label: 'BTC'
              }
            }
          }

          if (val.query.tx) {
            this.search = val.query.tx;
            const hashValidationResult = await validate(this.search, 'txHash', {name: 'Search value'})
            if (this.search && hashValidationResult.valid) {
              this.SET_VALIDATE_HASH(true)
              this.SET_VALIDATE_ADDRESS(false)
            }
          }

          if (val.query.address) {
            this.search = val.query.address;
            const addressValidationResult = await validate(this.search, 'address:btc', { name: 'Search value' })
            if (this.search && addressValidationResult.valid) {
              this.SET_VALIDATE_HASH(false)
              this.SET_VALIDATE_ADDRESS(true)
            }
          }
        }

        if (!val.query.address && !val.query.tx && !this.searchValue) {
          this.search = ''
          this.SET_VALIDATE_HASH(false)
          this.SET_VALIDATE_ADDRESS(false)
        }

        if (this.cytoscape && this.cytoscape.search) {
          this.search = this.cytoscape.search
        }
      },
      immediate: true,
    },
    '$store.state.analytics.searchValue': 'setSearchValue',
  },
  created() {
    this.setParams()
    this.$root.$on('search', this.setParams);
  },
  beforeDestroy() {
    this.$root.$off("search");
  },
  methods: {
    ...mapMutations({
      SET_STEPPED_STATE: 'analytics/SET_STEPPED_STATE',
      SET_VALIDATE_HASH: 'analytics/SET_VALIDATE_HASH',
      SET_VALIDATE_ADDRESS: 'analytics/SET_VALIDATE_ADDRESS',
      SET_SEARCH_VALUE: 'analytics/SET_SEARCH_VALUE',
      SET_SEARCH_TYPE: 'analytics/SET_SEARCH_TYPE',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA'
    }),
    handleCoinSelect(data) {
      this.SET_COIN_TYPE(data.key)
      this.SET_COIN_DATA(data)
    },
    handleToggleViewMenu(val) {
      if (val) {

        const { label, address } = JSON.parse(localStorage.getItem('label-view'))

        if (label && address) {
          this.label = label
          this.address = address
        }

        const { amount, hash } = JSON.parse(localStorage.getItem('edge-view'))

        if (amount && hash) {
          this.amount = amount
          this.hash = hash
        }

        this.cytoscape.cy.nodes().find(el => el.data('type') === 'cluster')
          ? this.viewMode = 'cluster'
          : this.viewMode = 'address'
      }
    },
    setSearchValue(val) {
      this.search = val
    },
    async searching(value) {
      const valid = new RegExp(this.coinData.addressRegex).test(value) || new RegExp(this.coinData.txRegex).test(value)

      if (!valid) {
        this.$toasted.global.error({ message: 'Search value is not valid'})
        return
      }
      if (value) {
        this.SET_SEARCH_VALUE(value)
      }

      if (value) {
        this.SET_STEPPED_STATE({ undo: false, redo: false })

        if (value.substring(0, 2) === '0x') {
          if (value.length < 60) {
            this.$root.$emit('search');
            this.SET_VALIDATE_HASH(false)
            this.SET_VALIDATE_ADDRESS(true)
            this.SET_SEARCH_TYPE(this.coinType)
            //TODO remove this
            await this.$router.push({ name: 'analytics', query: { type: this.coinType, address: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
            return
          } else {
            this.$root.$emit('search');
            this.SET_VALIDATE_HASH(true)
            this.SET_VALIDATE_ADDRESS(false)
            this.SET_SEARCH_TYPE(this.coinType)
            //TODO remove this
            await this.$router.push({
              name: 'analytics',
              query: {type: this.coinType, tx: value, 'search-id': (Math.random() * 1000).toFixed(0).toString()}
            })
            return
          }
        }

        const hashValidationResult = await validate(value, 'txHash', { name: 'Search value' })
        if (hashValidationResult.valid) {
          this.$root.$emit('search');
          this.SET_VALIDATE_HASH(true)
          this.SET_VALIDATE_ADDRESS(false)
          this.SET_SEARCH_TYPE('tx')
          //TODO remove this
          await this.$router.push({ name: 'analytics', query: { type: this.coinType, tx: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
          return
        }
        // const addressValidationResult = await validate(value, 'address:btc', { name: 'Search value' })
        // if (addressValidationResult.valid) {
          this.$root.$emit('search');
          this.SET_VALIDATE_HASH(false)
          this.SET_VALIDATE_ADDRESS(true)
          this.SET_SEARCH_TYPE('address')
          await this.$router.replace({ name: 'analytics', query: { type: this.coinData.key, address: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
          return
        // }
        // this.$toasted.global.error({ message: 'Search value is not valid'})

        // this.SET_VALIDATE_HASH(false)
        // this.SET_VALIDATE_ADDRESS(false)
      }
    },
    clearValue() {
      this.SET_VALIDATE_HASH(false)
      this.SET_VALIDATE_ADDRESS(false)
      this.$router.replace('analytics')
      localStorage.removeItem('graph')
    },
    provide(val) {
      this.$emit('set-transaction', val)
    },
    provideHide(val) {
      this.$emit('search-and-hide-elements', val)
    },
    provideTx(val) {
      this.$emit('add-tx-data', val)
    },
    setParams() {
      const localViewMode = localStorage.getItem('view-mode')

      localViewMode ? this.viewMode = localViewMode : 'address'

      this.changeViewMode(this.viewMode)

      const localLabelView = JSON.parse(localStorage.getItem('label-view'))

      if (localLabelView) {
        this.address = localLabelView.address
        this.label = localLabelView.label
      } else {
        this.address = false
        this.label = true
      }

      this.changeNodeLabels()

      const localEdgeView = JSON.parse(localStorage.getItem('edge-view'))

      if (localEdgeView) {
        this.amount = localEdgeView.amount
        this.hash = localEdgeView.hash
      } else {
        this.amount = false
        this.hash = true
      }

      this.changeEdgeLabels()
    },
    exportCaseHandler($event) {
      if (this.caseData) {
        this.save($event)
      } else {
        this.exports($event)
      }

      this.$refs.casesDropdown.isOpen = false
    },
    exports($event) {
      this.$emit('export', $event)
    },
    save($event) {
      this.$emit('save', $event)
    },
    saveAs() {
      this.$emit('save-as', 'cases')
    },
    changeViewMode(val) {
      this.$emit('change-view-mode', val)
      localStorage.setItem('view-mode', val)
    },
    changeNodeLabels() {
      this.$emit('change-node-labels', { label: this.label, address: this.address })
      localStorage.setItem('label-view', JSON.stringify({ label: this.label, address: this.address }))
    },
    changeEdgeLabels() {
      this.$emit('change-edge-labels', { amount: this.amount, hash: this.hash })
      localStorage.setItem('edge-view', JSON.stringify({ amount: this.amount, hash: this.hash }))
    },
    openFileInput() {
      this.$refs.file.click()
    },
    onFileChange(e) {
      const [file] = e.target.files
      e.target.value = ''

      if (file) this.$emit('import', file)
    },
  },
}
</script>

<style>
.dropdowns__wrap {
  background: var(--white);
  border-radius: 3px;
  display: flex;
  padding: 3px;
  height: 30px;
}

.wrap {
  flex-wrap: wrap;
}

.coin-select .vs__dropdown-toggle{
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  /*height: 35px !important;*/
  min-height: 30px;
  width: 102px;
}
</style>
