<template>
  <div
    v-if="value"
    v-on-clickaway="closeMenu"
    class="gl-context-menu"
    oncontextmenu="return false"
    :style="contextStyle"
  >
    <div>
      <div v-if="tracingData.prevTx && tracingData.prevTx.length">
        <div class="tracing-list__header">
          TRACE BACK
        </div>
        <div class="tracing-list__wrap">
          <div
            v-for="(item, index) in tracingData.prevTx"
            :key="index"
          >
            <div
              class="tracing-list__item"
              @click="trace(item, 'prevTx')"
              @mouseenter="highlighting(item)"
              @mouseleave="toRegular(item)"
            >
              <span class="mr-3">{{ item.formattedAmount }}</span>
              <GlIcon
                :height="24"
                :name="'output-double-arrow'"
                :width="24"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="tracingData.nextTx && tracingData.nextTx.length"
        class="tracing-list__list"
      >
        <div class="tracing-list__header">
          TRACE FORWARD
        </div>
        <div class="tracing-list__wrap">
          <div
            v-for="(item, index) in tracingData.nextTx"
            :key="index"
          >
            <div
              class="tracing-list__item"
              @click="trace(item, 'nextTx')"
              @mouseenter="highlighting(item)"
              @mouseleave="toRegular(item)"
            >
              <span class="mr-3">{{ item.formattedAmount }}</span>
              <GlIcon
                :height="24"
                :name="'input-double-arrow'"
                :width="24"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { mixin as clickaway } from 'vue-clickaway'
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlMenuItem,
    GlIcon,
  },
  mixins: [clickaway],
  props: {
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: true,
    },
    tracingData: {
      type: Object,
      default: () => ({}),
    },
    cytoscape: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contextStyle: {
        left: `${ this.position.x }px`,
        top: `${ this.position.y }px`,
      },
    }
  },
  computed: {
    ...mapState('analytics', ['isAddress', 'coinType']),
  },
  watch: {
    position: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          const menu = this.$el
          const { x, y } = this.position
          const availableWidth = menu.parentNode.clientWidth - x
          const availableHeight = menu.parentNode.clientHeight - y
          this.contextStyle = {
            left: `${ menu.clientWidth > availableWidth ? x - menu.clientWidth : x }px`,
            top: `${ menu.clientHeight > availableHeight ? y - menu.clientHeight : y }px`,
          }
        })
      },
    },
  },
  methods: {
    highlighting(data) {
      this.cytoscape.cy.edges().forEach(el => {
        if (el.data('additionalData')._id && el.data('additionalData')._id === data.txId) {
          el.addClass('highlightersEdge')
        } else {
          el.addClass('pale')
        }
      })
    },
    toRegular(data) {
      this.cytoscape.cy.edges().forEach(el => {
        if (el.data('additionalData')._id && el.data('additionalData')._id === data.txId) {
          el.removeClass('highlightersEdge')
        } else {
          el.removeClass('pale')
        }
      })
    },
    trace(item, direction) {
      this.$emit('trace', item, this.tracingData, direction)
      this.closeMenu()
    },
    closeMenu() {
      this.cytoscape.cy.edges().forEach(el => {
        el.removeClass('highlightersEdge')
        el.removeClass('pale')
      })
      this.$emit('input', false)
    },
  },
}
</script>

<style>
.tracing-list__wrap {
  max-height: 140px;
  overflow: auto;
  margin-bottom: 8px;
  box-shadow: 0 8px 5px -2px var(--pale-grey);
}

.tracing-list__header {
  padding: 10px;
  background-color: var(--perwinkle);
  color: var(--space-cadet);
  font-weight: 500;
  border-radius: 3px;
  border: 1px solid var(--perwinkle);
  margin-bottom: 2px;
}

.tracing-list__item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid transparent;
}

.tracing-list__item:hover {
  background-color: var(--pale-grey);
  border: 1px solid var(--soft-blue);
  cursor: pointer;
}
</style>
