<template>
  <div class="fs-14">
    <div
      v-if="contractData.type === 'address' || !contractData.type"
      class="sidebar__section px-3"
    >
      <p class="sidebar__analytics-label">
        Address / Ens name
      </p>
      <div
        class="flex align-center fullwidth"
        :title="selectedElement.value"
      >
        {{ selectedElement.data.id }} / {{ contractData.ens || '--' }}
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.data.id)"
        />
        <gl-menu-item
          v-if="isAdmin"
          v-tooltip.top="'Edit labels'"
          class="pb-1"
          icon="edit-block-active"
          :icon-height="24"
          :icon-width="24"
          @click="toLabeling(selectedElement.data.id)"
        />
      </div>
    </div>
    <div
      v-else-if="contractData && contractData.type === 'tokenContract'"
      class="stat px-3 fullwidth"
    >
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Address / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.address || '--' }} / {{ contractData.ens || '--' }}
          <gl-menu-item
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(contractData.address)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-tooltip.top="'Edit labels'"
            class="pb-1"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(contractData.address)"
          />
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Deployer / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractDeployer && contractData.contractDeployer.address || '--' }} / {{ contractData.contractDeployer && contractData.contractDeployer.ens || '--' }}
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Owner / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractOwner && contractData.contractOwner.address || '--' }} / {{ contractData.contractOwner && contractData.contractOwner.ens || '--' }}
        </div>
      </div>
    </div>
    <div
      v-else-if="contractData && contractData.type === 'contract'"
      class="stat px-3 fullwidth"
    >
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Address / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractAddress && contractData.contractAddress.address || contractData.address || '--' }} / {{ contractData.contractAddress && contractData.contractAddress.ens || '--' }}
          <gl-menu-item
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(contractData.contractAddress && contractData.contractAddress.address || contractData.address)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-tooltip.top="'Edit labels'"
            class="pb-1"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(contractData.contractAddress && contractData.contractAddress.address || contractData.address)"
          />
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Deployer / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractDeployer && contractData.contractDeployer.address || '--' }} / {{ contractData.contractDeployer && contractData.contractDeployer.ens || '--' }}
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Owner / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractOwner && contractData.contractOwner.address || '--' }} / {{ contractData.contractOwner && contractData.contractOwner.ens || '--' }}
        </div>
      </div>
    </div>
    <div class="stat px-3">
      <div class="stat-tag full mb-1">
        <InfoBlock
          :class="{'full': !selectedElement.data.nodeData.name}"
          font-size="14px"
          label="Owner"
          :loading="isAddressDataLoading"
          :value="addressData.owner"
        />
        <InfoBlock
          v-if="selectedElement.data.nodeData.hasSymbol && contractData && contractData.type === 'tokenContract'"
          font-size="14px"
          label="Token"
          :loading="isAddressDataLoading"
          :value="selectedElement.data.nodeData.name"
        >
          <template #icon>
            <GlImg
              v-if="addressData.contractInfo && addressData.contractInfo.icon"
              :src-val="addressData.contractInfo && addressData.contractInfo.icon"
            />
          </template>
        </InfoBlock>
      </div>
      <div class="sidebar__section">
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <VueSkeletonLoader
          v-if="isAddressDataLoading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div
          v-else-if="addressData && addressData.scoringList && addressData.scoringList.length > 0"
        >
          <GlTag
            v-for="(tag, index) in addressData.scoringList"
            :key="index"
            class="mr-1 mb-1"
            :class="{'hidden': index > 6}"
            :score="tag.score"
            :tag="capitalizeFirstLetter(tag.name)"
          />
          <div
            v-if="addressData.scoringList.length > 6"
            class="all-tags-btn"
            @click="changeTab('tags')"
          >
            All tags
          </div>
        </div>
        <div v-else>
          --
        </div>
      </div>
    </div>
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <div
          v-if="tab.visible"
          class="tabs-item"
          :class="[{'tabs-item--active': activeTab === tab.name}, {
            'hatching': (isContract && tab.name === 'sof') || !addressData || addressData && addressData.scoringList && !addressData.scoringList.length
              && addressData.meta && !addressData.meta.length
              && addressData.tags && !addressData.tags.length
              && tab.name === 'meta'
          }]"
          @click="changeTab(tab.name)"
        >
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'general'"
      class="stat px-3"
    >
      <div class="stat full mt-3">
        <div class="stat-tag full">
          <InfoBlock
            label="balance"
            :loading="loadingBalance"
            :value="balance === 0 ? '0' : toComaSeparate(String(formatBtcAmount(balance, true, coinData.family, token ? token.symbol : coinData.label)))"
          />
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'tx'"
      class="stat px-3"
    >
      <div class="stat-tag full">
        <div class="flex full mt-3">
          <div class="column full mr-2">
            <div class="flex space-between mb-2 align-center">
              <div class="sidebar__analytics-label">
                Filter by date & time
              </div>
              <gl-menu-item
                class="flex align-center pt-3"
                icon="delete-action"
                :icon-height="24"
                :icon-width="24"
                label="Clear all"
                @click="clearDate"
              />
            </div>
            <date-picker
              ref="datePicker"
              v-model="transactionsListDates"
              class="mb-2"
              :disabled="loading"
              placeholder="Select datetime range"
              range
              :show-time-panel="showTimeRangePanel"
              type="datetime"
              @clear="handleDateClose"
              @close="handleRangeClose"
            >
              <template v-slot:footer>
                <div class="flex space-between">
                  <div>
                    <button
                      class="mx-btn mx-btn-text link"
                      @click="toggleTimeRangePanel"
                    >
                      {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </div>
                  <div>
                    <button
                      class="mx-btn mx-btn-text cancel-date-range mr-2"
                      @click="handleDateClose"
                    >
                      Cancel
                    </button>
                    <button
                      class="mx-btn mx-btn-text apply-date-range"
                      @click="setDate(transactionsListDates)"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </template>
            </date-picker>
          </div>
        </div>
        <div class="flex space-between fullwidth mt-3">
          <div
            v-for="(item, index) in transactionsListFilters"
            :key="index"
            class="filters-tab"
            :class="[{'filters-tab__active': selectedFilter === item.type}, {'filters-tab__disabled': checkTxsCount(item.totalKey, contractData)}]"
            @click="changeFilterType(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="stat full mt-3">
          <div class="stat-tag full">
            <div
              v-if="selectedFilter === 'tokens'"
              class="column mr-4"
            >
              <div class="flex space-between mb-2 align-center">
                <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                  filter by token
                </div>
              </div>
              <vSelect
                v-model="token"
                class="tokens-list mr-1"
                :clearable="false"
                :disabled="getTokensLoading"
                label="symbol"
                :options="tokensList"
                placeholder="Select Token"
                @input="handleSelectToken"
              >
                <template v-slot:selected-option="{ symbol, icon }">
                  <GlImg
                    v-if="icon"
                    class="mr-1"
                    :src-val="icon"
                  />
                  {{ symbol }}
                </template>
                <template v-slot:option="option">
                  <GlImg
                    v-if="option.icon"
                    class="mr-1"
                    :src-val="option.icon"
                  />
                  {{ option.symbol }}
                </template>
              </vSelect>
            </div>
            <InfoBlock
              label="balance"
              :loading="loadingBalance"
              :value="balance === 0 ? '0' : toComaSeparate(String(formatBtcAmount(balance, true, coinData.family, token ? token.symbol : coinData.label)))"
            />
            <InfoBlock
              label="Number of txs"
              :loading="loadingMetrics || loading"
              :value="toComaSeparate(String(total))"
            />
          </div>
        </div>
        <o-table
          ref="transactionActions"
          checkable
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          class="pagination-table inputs-outputs-table"
          :data="localData"
          detail-transition="fade"
          detailed
          detailed-class="eth-tx-actions-detailed"
          hoverable
          :loading="loading || loadingDetails"
          @check="handleCheck"
          @details-close="closeDetails"
          @details-open="getDetails"
        >
          <template
            slot="detail"
            slot-scope="props"
          >
            <div class="eth-tx-actions-detailed">
              <div
                v-if="detailData.actions && detailData.actions.length"
                class="sidebar__analytics-label"
              >
                Transaction action
              </div>
              <div
                v-for="(action, index) in detailData.actions"
                :key="index"
                class="flex mb-2"
              >
                <div
                  v-if="action.type === 'swap'"
                  class="fullwidth flex"
                >
                  Swap {{ action.from.amountText }} for {{ action.to.amountText }} on<span
                    class="ml-1 mr-1 link"
                    @click="openInNewTabAddress(action.address)"
                  >{{ action.owner ? action.owner : action.address || '--' }}</span>
                </div>
              </div>
              <div
                v-if="detailData.transfers && detailData.transfers.length"
                class="sidebar__analytics-label mt-3"
              >
                Executed transfers
              </div>
              <div
                v-for="(transfer, index) in detailData.transfers"
                :key="index"
                class="flex mb-2"
              >
                <div
                  v-if="transfer.type === 'transfer'"
                  class="fullwidth flex white-space-nowrap"
                >
                  Transfer {{ transfer.amountText }} from <span
                    class="link ml-1 mr-1"
                    @click="openInNewTabAddress(transfer.from.address)"
                  >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                    class="link ml-1"
                    @click="openInNewTabAddress(transfer.to.address)"
                  >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                </div>
                <div
                  v-if="transfer.type === 'method'"
                  class="fullwidth flex"
                >
                  {{ transfer.method }} {{ transfer.amountText }} from <span
                    class="link ml-1 mr-1"
                    @click="openInNewTabAddress(transfer.from.address)"
                  >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                    class="link ml-1"
                    @click="openInNewTabAddress(transfer.to.address)"
                  >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                </div>
                <div
                  v-if="transfer.type === 'unknown'"
                  class="fullwidth flex"
                >
                  <span
                    class="link ml-1 mr-1"
                    @click="externalReference(`https://api.etherface.io/v1/signatures/hash/all/${transfer.topic}/1`)"
                  >{{ transfer.topic }}</span> {{ transfer.amountText }} from <span
                    class="link ml-1 mr-1"
                    @click="openInNewTabAddress(transfer.from.address)"
                  >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                    class="link ml-1"
                    @click="openInNewTabAddress(transfer.to.address)"
                  >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                </div>
              </div>
              <div class="sidebar__analytics-label mt-3">
                Transaction fee
              </div>
              <div>
                {{ detailData.fee && detailData.fee.amountText || '--' }}
              </div>
            </div>
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!loading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="date"
            label="Date"
            width="170"
          >
            <div>
              {{ formatDate(props.row.timestamp * 1000, 'yyyy-MM-dd HH:mm:ss') || '-' }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="txHash"
            label="transaction"
          >
            <div
              class="pointer link"
              @click="openInNewTab(props.row.hash)"
            >
              {{ trancateString(props.row.hash, 16) }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="actions"
            label="Actions"
          >
            <div class="link" >
              <AddressTxActions
                :tx="props.row.hash"
              />
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            label="IN / OUT"
            width="80"
          >
            <GlIcon
              v-if="getTxDirection"
              :height="24"
              :name="getTxDirection(props.row.type)"
              :width="24"
            />
          </o-table-column>
        </o-table>
        <div
          v-if="localData.length > 0"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="localData.length"
            class="transactions-list-pagination"
            :current.sync="currentPage"
            order="centered"
            :per-page="perPage"
            size="small"
            :total="totalPages * perPage"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'meta'"
      class="pa-3"
    >
      <AddressTagsTable
        :address="selectedElement.data.id"
        :data="addressData"
      />
    </div>
    <div
      v-if="activeTab === 'sof'"
      class="pa-3 relative"
    >
      <div v-if="calcLoading">
        <gl-loader class="table__loader" />
      </div>
      <div v-else>
        <div class="flex space-between align-flex-end">
          <div class="column">
            <div class="flex space-between mb-2 align-center">
              <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                filter by token
              </div>
            </div>
            <vSelect
              v-model="token"
              class="tokens-list mr-1"
              :clearable="false"
              :disabled="getTokensLoading"
              label="symbol"
              :options="tokensList"
              placeholder="Select Token"
              @input="handleSelectToken"
            >
              <template v-slot:selected-option="{ symbol, icon }">
                <GlImg
                  v-if="icon"
                  class="mr-1"
                  :src-val="icon"
                />
                {{ symbol }}
              </template>
              <template v-slot:option="option">
                <GlImg
                  v-if="option.icon"
                  class="mr-1"
                  :src-val="option.icon"
                />
                {{ option.symbol }}
              </template>
            </vSelect>
          </div>
          <div class="flex fs-14 align-end mr-3">
            Amount
            <div class="px-1 switch__wrap">
              <input
                id="switch"
                v-model="trackActivator"
                type="checkbox"
                @click="trackBy()"
              >
              <label for="switch">Toggle</label>
            </div>
            %
          </div>
        </div>
        <div
          v-if="!allDataSource.length"
          class="empty-users-data text-center"
        >
          Data is empty
        </div>
        <div
          v-if="allDataSource.length > 0"
          class="flex align-center justify-center relative"
        >
          <gl-pie-chart
            ref="pie"
            :center="['50%', '60%']"
            class="relative aml-detected-list"
            :data-source="allDataSource"
            :height="470"
            :width="'100%'"
            @mouseout="sectionData = null"
            @move="handlePieHover"
          >
            <template slot="tooltip">
              <transition name="fade">
                <div
                  v-if="sectionData"
                  id="sourcePie"
                  class="testPie"
                >
                  <div
                    v-for="(owner, index) in sectionData.owners"
                    :key="index"
                    :class="{'mb-2': index < sectionData.owners.length - 1}"
                  >
                    <div class="flex align-center">
                      <div class="sub-types-amount">
                        <span class="">
                          {{ trackByField === 'share'
                            ? formatShare(allDataSource.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                            : formatBtcAmount(toDecimalVal(owner.amount, owner.decimals), true, coinData.family, owner.symbol) }}
                        </span>
                      </div>
                      <div
                        v-tooltip.top="capitalizeFirstLetter(owner.name)"
                        class="ml-3 ellipsis flex flex-start flex flex-1 sub-types-owner"
                      >
                        {{ capitalizeFirstLetter(owner.name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
          </gl-pie-chart>
          <div class="risk-score-wrap">
            <strong class="risk-score-label">
              Risk Score
            </strong>
            <div
              class="risk-score-value"
              :style="`color: ${findColorByTypeScore(totalFunds)}`"
            >
              {{ formatFunds(totalFunds, false) }}
            </div>
          </div>
        </div>
        <div class="gl-form__actions">
          <button
            v-if="!addressData.inMonitoring"
            class="gl-button gl-button--light full-submit gl-form__button gl-button--padder mr-4"
            :disabled="calcLoading"
            @click="addToMonitoringList(addressData.address || selectedElement.data.id)"
          >
            Add to Monitoring
          </button>
          <button
            class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
            @click="toFullReport(selectedElement.data.id)"
          >
            get full report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapState} from 'vuex'
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate, toDecimalVal } from '@/utils/formatNumber'
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import {findColorByTypeScore, tokensSorting} from "@/utils/cytoskape-ui-rules";
import { formatFunds } from "@/utils/report-data-formatter";
import {featureAccess, isExceptionStatus} from "@/utils/accesses";
// Components
import GlImg from "@/components/gl-img.vue";
import vSelect from "vue-select";
import GlTag from '@/components/gl-tag';
import GlIcon from "@/components/gl-icon";
import GlPieChart from "@/components/charts/gl-pie-chart";
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import VueSkeletonLoader from 'skeleton-loader-vue';
import AddressTagsTable from "@/pages/analytics/analytics-info-blocks/components/addressTagsTable";
import AddressTxActions from './components/addressTxActions';
// Libs
import _ from "lodash";
import moment from "moment";
import {formatShare, formatter} from "@/utils/sourcesFormatter";
import GlButton from "@/components/gl-button.vue";
import {ethIcon} from "@/assets/static/eth-base-64-icon";

export default {
  name: 'TransactionInfo',
  components: {
    GlButton,
    GlPieChart,
    GlImg,
    GlTag,
    GlIcon,
    vSelect,
    GlLoader,
    InfoBlock,
    GlMenuItem,
    AddressTagsTable,
    VueSkeletonLoader,
    AddressTxActions
  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    searchMode: {
      type: String,
      default: 'general',
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      selectedFilter: 'main',
      calcLoading: false,
      totalFunds: 0,
      sectionData: null,
      monitoringCalculateHandler: null,
      localData: [],
      allDataSource: [],
      tokensList: [],
      checkedRows: [],
      outputsCheckedRows: [],
      transactionsListDates: [],
      addressData: {},
      transactionData: {},
      contractData: {},
      getTokensLoading: false,
      loading: false,
      forward: false,
      backward: false,
      loadingTxs: false,
      loadingDetails: false,
      showTimePanel: false,
      loadingMetrics: false,
      loadingBalance: false,
      showTimeRangePanel: false,
      isAddressDataLoading: false,
      token: { address: '', symbol: 'ETH' },
      tabs: [{
        name: 'general',
        title: 'General',
        visible: true
      }, {
        name: 'tx',
        title: 'Transactions',
        visible: true
      },{
        name: 'sof',
        title: 'Source Of Funds',
        visible: featureAccess('REPORT')
      }, {
        name: 'meta',
        title: 'Tags & Metadata',
        visible: true
      }],
      activeTab: 'general',
      defaultTab: 'general',
      trackByField: 'share',
      trackActivator: 'share',
      balance: null,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      detailData: {}
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    ...mapGetters('user', ['isAdmin']),
    isContract() {
      return this.selectedElement?.data?.nodeData?.isContract
    },
    elementLabel() {
      return (this.selectedElement && this.selectedElement.type || '').replace('tx', 'Transaction Hash')
    },
    transactionsListFilters() {
      return [
        {
          label: `${this.coinData.label} Transactions`,
          type: 'main',
          totalKey: 'totalETHTxs',
        },
        {
          label: 'Internal TXs',
          type: 'internal',
          totalKey: 'totalInternalTxs',
        },
        {
          label: 'Token Transfers',
          type: 'tokens',
          totalKey: 'totalTokensTxs',
        },
      ]
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  async mounted() {
    if (!this.token.address) {
      this.token.symbol = this.coinData.label
      this.token.icon = ethIcon
    }
    await this.loadMetrics()
    await this.changeTab(this.defaultTab);
    await this.updateData()
  },
  methods: {
    tokensSorting,
    toDecimalVal,
    formatDate,
    formatShare,
    trancateString,
    findColorByTypeScore,
    formatFunds,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    checkTxsCount(totalKey, contractData) {
        // eslint-disable-next-line no-prototype-builtins
      return contractData.hasOwnProperty(totalKey) && contractData[totalKey] === 0
    },
    changeFilterType({ type, totalKey }) {
      this.loading = true
      if (this.checkTxsCount(totalKey, this.contractData)) return;

      this.currentPage = 1

      if (type === 'tokens') {
          this.getTokensLoading = true
          this.getAddressTokens(this.selectedElement.data.id).then(({ data }) => {
              // eslint-disable-next-line no-prototype-builtins
              this.tokensList = this.tokensSorting(data)

              if (this.tokensList.length) {
                  this.token = this.tokensList[0]
              }

              this.loadAddressData(true, type)
          }).finally(() => {
              this.getTokensLoading = false
              this.loading = false
          })

          return
      }

      else {
          this.token.address = ''
          this.token.symbol = this.coinData.label
          this.loadAddressData(true, type)
      }
    },
    addToMonitoringList(address) {
        this.calcLoading = true
        this.addToMonitoring({
            address,
            checkLastTen: false,
            blockchain: this.coinData.label
        }).then(({ success, data }) => {
            if (success) {
                this.$toasted.global.success({message: `Address added to monitoring`})
                this.getAddressesMonitoring({ skip: 0, count: 1, address})
                    .then(() => {
                        this.monitoringCalculateHandler = setTimeout(() => {
                            this.monitoringGettingInterval(address)
                        }, 3000);
                    })
                this.addressData.inMonitoring = true
            }
            else {
              this.calcLoading = false
              if (!isExceptionStatus(data.status)) {
                this.$toasted.global.error({message: `${data.message}`})
              }
            }
        })
    },
    monitoringGettingInterval(address) {
      this.monitoringCalculateHandler = setTimeout(() => {
        this.getAddressesMonitoring({ skip: 0, count: 1, address})
          .then(({ data }) => {
            if (data.list.length) {
                this.calcLoading = false
                clearTimeout(this.monitoringCalculateHandler);
                this.loadCalcData()
            } else {
                this.monitoringGettingInterval(address)
            }
          })
        }, 3000)
    },
    getDetails(item) {
        this.$refs.transactionActions.visibleDetailRows.forEach(row => {
            if (item.hash !== row.hash) {
                this.$refs.transactionActions.closeDetailRow(row)
            }
        })
        this.loadingDetails = true
        this.detailData = {}
        this.getTransactionActions({ tx: item.hash }).then(({ data, success }) => {
            if (!success) {
                this.$toasted.global.error({message: `${data.message}`})
            }
            this.detailData = data
            this.loadingDetails = false
        }).finally(() => {
            this.loadingDetails = false
        })
    },
    // Temp solution
    // seeMoreDetails(hash) {
    //   this.loadingDetails = true
    //   this.getTransactionActions({ tx: hash }).then(({ data, success }) => {
    //     if (!success) {
    //       this.$toasted.global.error({message: `${data.message}`})
    //     }
    //     this.detailData.actions = this.detailData.actions.concat(data.actions)
    //     console.log(this.detailData.actions, 'actions list')
    //   }).finally(() => {
    //       this.loadingDetails = false
    //   })
    // },
    closeDetails() {
      this.detailData = {}
    },
    toFullReport(val) {
      const { href } = this.$router.resolve({ name: 'report', query: { address: val, type: this.coinType, token: this.token.address || ''}})
      window.open(href, '_blank')
    },
    externalReference(link) {
        const href = `${link}`
        window.open(href, '_blank')
    },
    openInNewTabAddress(address) {
      if (!address) {
          this.$toasted.global.error({message: `Empty data`})
          return
      }
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    loadMetrics() {
      this.loadingMetrics = true

      const sendData = {
        address: this.selectedElement.data.id,
        contract: this.token ? this.token.address : undefined,
        type: this.selectedFilter
      }

      this.getAddressMetrics(sendData).then(({ data: { totalTxs } }) => {
        this.total = totalTxs.total
      }).finally(() => {
        this.loadingMetrics = false
      })
    },
    async updateData() {
      this.changeTab(this.defaultTab);
      this.currentPage = 1
      this.token = { address: '', symbol: this.coinData.label }

      await this.loadAddressBalance();

      switch (this.activeTab) {
        case 'tx':
          await this.loadAddressData();
          break;
      }
    },
    ...mapActions('analytics', [
      'getTxEthData',
      'getAddressData',
      'getAddressTokens',
      'getAddressBalance',
      'getAddressMetrics',
      'getEthAddressInfo',
      'getEthAddressData',
      'getAddressEthScore',
      'getTxEthScore',
      'getAddressTokens',
      'getTransactionActions',
    ]),
    ...mapActions('monitoring', ['addToMonitoring', 'getAddressesMonitoring']),
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    toLabeling(val) {
      const { href } = this.$router.resolve({ name: 'labeling', query: { address: val, tab: 'labeling', type: this.coinType }})

      window.open(href, '_blank')
    },
    setDate(date) {
      const [from, to] = date

      if (from, to) {
        this.startRangeDate = this.toSeconds(from);
        this.endRangeDate = this.toSeconds(to);
        this.currentPage = 1
        this.$refs.datePicker.closePopup()
        this.loadAddressBalance()
        this.loadAddressData(true, this.selectedFilter);
      } else this.clearDate()
    },
    toSeconds(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).unix()
    },
    getTxDirection(type) {
      if ((type.find(el => el === 'input') && type.find(el => el === 'output')) || type.find(el => el === 'swap')) return 'input-output-arrow'
      if (type.find(el => el === 'input')) return 'input-arrow'
      if (type.find(el => el === 'output')) return 'output-arrow'
      return null
    },
    changeTab(tab) {
      if (!this.addressData?.scoringList?.length
          && !this.addressData?.meta?.length
          && !this.addressData?.tags?.length
          && tab === 'meta') return

      if (this.isContract) return;

      this.activeTab = tab
      switch (tab) {
        case 'tx':
          this.loadAddressData();
          break;
        case 'sof':
          this.loadCalcData();
          break;
        case 'general':
          // this.loadAddressBalance();
          break;
      }
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      this.sectionData = $event.data
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.allDataSource = this.allDataSource.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount} ${this.capitalizeFirstLetter(item.funds.type)}`,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item.share) : formatBtcAmount(this.toDecimalVal(item.amount, item.decimals), true, 'eth', item.swapData ? item.swapData.to.symbol : item.symbol)}`,
      }))

      this.sortedPieData()
    },
    sortedPieData() {
      this.allDataSource = this.allDataSource.sort(({ funds: a }, { funds: b }) =>
          a.default - b.default || b.score - a.score
      )
    },
    loadCalcData() {
      this.calcLoading = true
      this.getTokensLoading = true
      this.getAddressTokens(this.selectedElement.data.id).then(({ data }) => {
        data.unshift({ address: '', symbol: this.coinData.label, icon: ethIcon })
          // eslint-disable-next-line no-prototype-builtins
        this.tokensList = this.tokensSorting(data)
        const tokenIndex = this.tokensList.findIndex(item => item.address === this.token.address)

        if (tokenIndex === -1) {
          this.token = this.tokensList[0]
        } else {
          this.token = this.tokensList[tokenIndex]
        }
      }).catch(({ response: { data } }) => {
        if (data?.data?.message) {
          this.$toasted.global.error({message: `${data.data.message}`})
        }

        this.calcLoading = false
      }).finally(() => {
        this.getTokensLoading = false
      })
      this.getAddressEthScore({ address: this.selectedElement.data.id, token: this.token.address, tokenTicker: this.token.symbol }).then(({ data: { sources, totalFunds, currencies }}) => {
        this.totalFunds = totalFunds || 0

        const groupedSourcesByType = formatter(sources, 'funds.type')

        this.allDataSource = groupedSourcesByType.map(item => {
          const localCurrency = currencies[item.currency] || {}
          return {
            ...item,
          owners: sources
              .filter((v) => v.funds.type === item.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
                formattedAmount: this.formatBtcAmount(v.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.key, localCurrency.currency),
              })),
          funds: {
            ...item.funds,
            default: item.funds.default || false
          },
          key: item.funds.type,
          formattedAmount: this.formatBtcAmount(item.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.key, localCurrency.currency),
          name: `${this.trackByField === 'share'
            ? formatShare(item.share)
            : item.formattedAmount} ${this.capitalizeFirstLetter(item.funds.type)}`,
          tooltip: `${item.funds.type} ${formatShare(item.share)}`,
          pieValue: item.share,
          value: item.share,
          itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        }})

        console.log(this.allDataSource)

        this.combineSourcesOwners()
        this.sortedPieData()
      }).finally(() => {
        this.calcLoading = false
      })
    },
    combineSourcesOwners() {
      this.allDataSource.map((item) => {
        item.owners = formatter(item.owners, 'name')
      })
    },
    pageChange(page) {
      this.currentPage = page
      this.loadAddressData(true, this.selectedFilter)
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.$refs.datePicker.closePopup()
      this.loadAddressData(true, this.selectedFilter);
    },
    clearDate() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadAddressData(true, this.selectedFilter);
    },
    openInNewTab(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    handleSelectToken() {
      if (this.activeTab === 'sof') {
        this.loadCalcData()
      } else {
        this.currentPage = 1
        this.loadAddressBalance();
        this.loadAddressData(true, this.selectedFilter);
      }
    },
    loadEthData(tx, page, limit) {
      this.loading = true
      this.getTxEthData({
        tx,
        page,
        limit
      }).then(({data, success}) => {
        if (!success) {
          this.$toasted.global.error({message: `${data.message}`})
          this.loading = false
          return
        }

        data.txs = data.txs.map(item => ({
          ...item,
          prevTx: item.prevTx || [],
          nextTx: item.nextTx || []
        }))

        this.$emit('set-eth-transaction', {
          search: tx,
          data
        })
      }).catch(({response: {data}}) => {
        this.$toasted.global.error({message: `${data.message}`})
      }).finally(() => {
        this.loading = false
      })
    },
    handleCheck(val) {
      const selectedEl = val[val.length - 1]
      const diffItem = _.difference(this.checkedRows, val)
      const isAdd = val.length > this.checkedRows.length

      if (isAdd) {
        this.loadEthData(selectedEl.hash, 1, 10)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.searchAndHideEthElementsByTxHash(ele.hash)
          })
        }
      }
    },
    checkingRows() {
      this.localData.map((item) => {
        if (this.cytoscape.searchElements(item.hash, false, false).length > 0) {
          this.checkedRows.push(item)
        }
      })
      this.preCheckedRows = this.checkedRows;
    },
    loadAddressBalance(withLoader = true) {
      if (withLoader) {
        this.loadingBalance = true
      }

      this.isAddressDataLoading = true

      this.getEthAddressInfo(this.selectedElement.data.id)
          .then(({ data, success }) => {
            if (success) {
              this.addressData = {
                ...data,
                tags: data.tags && data.tags.length > 0 ? data.tags : [],
                scoringList: this.formattingScoringList(data) || []
              };
              this.contractData = {
                ...data,
                type: data.addressType,
              }
            } else {
              this.addressData = {}
            }
            this.isAddressDataLoading = false
          }).finally(() => {
        this.loadingBalance = false
        this.isAddressDataLoading = false
      })

      this.getAddressBalance({
        address: this.selectedElement.data.id,
        contract: this.token ? this.token.address : undefined })
          .then(({ data }) => {
            this.balance = Number(data.balance.amount) || 0
            this.loadingBalance = false
          })
    },
    loadAddressData(withLoader = true, type = 'main') {
      this.selectedFilter = type
      if (withLoader) {
        this.loadingTxs = true
        this.loading = true
      }

      this.getEthAddressData({
        address: this.selectedElement.data.id,
        startRangeDate: this.transactionsListDates.length ? this.startRangeDate : null,
        endRangeDate: this.transactionsListDates.length ? this.endRangeDate : null,
        contract: this.token && type === 'tokens'? this.token.address : undefined,
        page: this.currentPage,
        only: 'list',
        type
      })
          .then(({ data: { list, forward, backward, pages }, success }) => {
              if (success) {
                  this.localData = list
                  this.totalPages = pages
                  this.backward = backward
                  this.forward = forward
              } else {
                  this.localData = []
                  this.totalPages = 0
                  this.backward = false
                  this.forward = false
              }
          }).finally(() => {
        this.loadingTxs = false
        this.loading = false
        this.checkingRows()
      })

      this.getAddressBalance({
          address: this.selectedElement.data.id,
          contract: this.token ? this.token.address : undefined })
          .then(({ data }) => {
              this.balance = Number(data.balance.amount) || 0
              this.loadingBalance = false
          }).catch(() => {
          this.loadingBalance = false
      })

      this.loadMetrics()
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid var(--pale-grey);
  justify-content: space-around;
}

.tabs-item {
  display: inline-block;
  padding: 12px 24px;
  color: var(--dark-grey-6-e);
  position: relative;
  cursor: pointer;
}

.tabs-item--active {
  font-weight: 600;
  color: #1b1f3b;
}

.tabs-item--active::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--soft-blue);
  transition: all 0.5s;
}

.hatching {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 49.9%, var(--dark-grey-d-3) 49.9%, var(--dark-grey-d-3) 60%, rgba(0, 0, 0, 0) 80%) fixed, linear-gradient(-45deg, var(--dark-grey-d-3) 16%, rgba(0, 0, 0, 0) 25%) fixed;
  background-size: 0.5em 0.5em;
  opacity: 0.6;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 255px;
}

.risk-score-label {
  font-size: 14px;
}

.testPie {
  /*min-width: 400px;*/
  width: auto;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.o-table__detail {
    background: var(--pale-grey) !important;
    box-shadow: inset 0 1px 3px var(--pale-grey) !important;
}

.eth-tx-actions-detailed {
    padding-left: 40px;
    max-height: 170px;
    overflow: auto;
}

.filters-tab {
    background: var(--pale-grey);
    color: var(--dark-grey-6-e);
    padding: 8px 24px;
    margin: 0 3px;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    width: inherit;
}

.filters-tab__active {
    background: var(--pale-grey);
    color: var(--soft-blue);
}

.filters-tab__disabled {
    color: var(--light-grey-blue);
    cursor: not-allowed;;
}
</style>
