<template>
  <div class="toolbar">
    <div class="flex mr-4 search-block">
      <gl-search-box
        v-model="search"
        button-text="find"
        dark-clear
        :disabled="isUnknowMode"
        full
        independent
        placeholder="Find on graph"
        @clear="clearAll"
        @search="$emit('search', $event, false)"
      />
    </div>
    <div class="flex align-center">
      <gl-checkbox
        v-model="semiAutomaticMode"
        class="semi-auto"
        label="Semi-automatic"
        @input="changeSemiAutomaticMode"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlSearchBox from '@/components/gl-search-box'
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import GlCheckbox from '@/components/gl-checkbox'
// Vuex
import { mapState } from 'vuex'

export default {
  components: {
    GlSearchBox,
    GlMenuItem,
    GlCheckbox,
    GlIcon
  },
  data() {
    return {
      semiAutomaticMode: false,
      search: ''
    }
  },
  computed: {
    ...mapState('analytics', ['isHash', 'isAddress']),
    modeExplain() {
      return 'Indicator of the node click-to-explore mode. Search for a transaction ID so by clicking on the node, you reveal the next spend of the output. Search for a wallet address to investigate all of its transactions.'
    },
    isUnknowMode() {
      return !this.isHash && !this.isAddress
    },
  },
  methods: {
    clearAll() {
      this.search = '';
      this.$emit('search', this.search)
    },
    changeSemiAutomaticMode() {
      this.$emit('set-semi-automatic', this.semiAutomaticMode)
    },
  }
}
</script>

<style>
.search-block {
  width: 100%;
  max-width: 550px;
}

.semi-auto {
  margin-left: -90px;
}
</style>
