<template>
  <div class="fs-14">
    <div class="sidebar__section px-3">
      <p class="sidebar__analytics-label">
        TX hash
      </p>
      <p
        class="sidebar__analytics-value flex align-center"
        :title="selectedElement.value"
      >
        <span>
          {{ selectedElement.value }}
        </span>
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.value)"
        />
      </p>
    </div>
    <div class="stat px-3">
      <div class="stat-tag full">
        <InfoBlock
          label="Total amount"
          :value="selectedElement.data.inputsAmount ? toComaSeparate(formatBtcAmount(selectedElement.data.inputsAmount)) : toComaSeparate(formatBtcAmount(selectedElement.totalAmount))"
        />
        <InfoBlock
          class="mr-4"
          label="Timestamp"
          :loading="isAddressDataLoading"
          :value="transactionInfoDataIO.timestamp ? formatDate(Number(transactionInfoDataIO.timestamp * 1000), 'dd.MM.yyyy HH:mm') : 0"
        />
        <InfoBlock
          label="Block"
          :loading="isTxInfoLoading"
          :value="transactionInfoDataIO.blockHeight && String(transactionInfoDataIO.blockHeight)"
        />
      </div>
    </div>
    <StatusBlock
      v-if="total > 10 || outputsCount > 10"
      class="mb-2 px-3"
      label="Limited number of inputs/outputs is displayed"
    />
    <div class="tabs">
      <div
        class="tabs-item"
        :class="[{'tabs-item--active': activeTab === 'inputs'}]"
        @click="changeTab('inputs')"
      >
        <div class="flex align-center">
          Inputs
          {{ displayInputsTotal.length }} / {{ total }}
          <gl-menu-item
            v-tooltip.top="`${inputsTotal} inputs\n${total} unique addresses`"
            icon="actions-info"
            :icon-height="16"
            :icon-width="16"
          />
        </div>
      </div>
      <div
        class="tabs-item"
        :class="[{'tabs-item--active': activeTab === 'outputs'}]"
        @click="changeTab('outputs')"
      >
        <div class="flex align-center">
          Outputs
          {{ displayOutputsTotal.length }} / {{ outputsCount }}
          <gl-menu-item
            v-tooltip.top="`${outputsTotal} outputs\n${outputsCount} unique addresses`"
            icon="actions-info"
            :icon-height="16"
            :icon-width="16"
          />
        </div>
      </div>
      <div
        v-if="featureAccess('REPORT')"
        class="tabs-item"
        :class="[{'tabs-item--active': activeTab === 'sof'}]"
        @click="changeTab('sof')"
      >
        <div class="flex align-center">
          Source of Funds
        </div>
      </div>
    </div>
    <div class="px-3">
      <div
        v-if="activeTab === 'sof'"
        class="relative"
      >
        <div v-if="calcLoading">
          <gl-loader class="table__loader" />
        </div>
        <div v-else>
          <div class="px-3 flex align-baseline space-between">
            <h2 class="sidebar__title mb-2">
              Source of Funds
            </h2>
            <div class="flex fs-14 align-end mr-3">
              Amount
              <div class="px-1 switch__wrap">
                <input
                  id="switch"
                  v-model="track"
                  type="checkbox"
                  @click="trackBy()"
                >
                <label for="switch">Toggle</label>
              </div>
              %
            </div>
          </div>
          <div class="flex align-center justify-center relative">
            <gl-pie
              ref="pie"
              class="relative aml-detected-list"
              :data-source="pieData"
              :height="470"
              :width="'100%'"
              @click="handlePieClick"
              @mouseout="sectionData = null"
              @move="handlePieHover"
            >
              <template slot="tooltip">
                <transition name="fade">
                  <div
                    v-if="sectionData"
                    id="sourcePie"
                    class="sofPie"
                  >
                    <div
                      v-for="(owner, index) in sectionData.owners"
                      :key="index"
                      :class="{'mb-2': index < sectionData.owners.length - 1}"
                    >
                      <div class="flex align-center">
                        <div class="sub-types-amount">
                          <span class="">
                            {{ trackByField === 'share' ? formatShare(owner[trackByField]) : formatBtcAmount(owner[trackByField]) }}
                          </span>
                        </div>
                        <div
                          v-tooltip.top="capitalizeFirstLetter(owner.name)"
                          class="ml-3 ellipsis flex flex-start flex flex-1 sub-types-owner"
                        >
                          {{ capitalizeFirstLetter(owner.name) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </template>
            </gl-pie>
            <div class="risk-score-wrap">
              <strong class="risk-score-label">
                Risk Score
              </strong>
              <div
                class="risk-score-value"
                :style="`color: ${findColorByTypeScore(totalFunds)}`"
              >
                {{ formatFunds(totalFunds, false) }}
              </div>
            </div>
          </div>
          <div class="gl-form__actions">
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(selectedElement.value)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3">
      <div
        v-if="activeTab === 'inputs'"
        class="stat"
      >
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          class="pagination-table inputs-outputs-table"
          :data="inputsList"
          hoverable
          :is-row-checkable="(row) => checkStateHandler(row, displayInputsTotal)
            || !Boolean(checkedRows.find(v => v.address === row.address))"
          @check="toggleAddressData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="address"
            label="Address"
          >
            <div
              class="link"
              @click="openInNewTabAddress(props.row.address)"
            >
              {{ trancateString(props.row.address, 8) }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="actions"
            label="Actions"
          >
            <div class="link">
              <AddressTxActions
                :address="props.row.address"
                @add-tx-data="$emit('add-tx-data', $event)"
              />
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            {{ props.row.coinbase ? toComaSeparate(formatBtcAmount(selectedElement.data.inputsAmount)) : toComaSeparate(formatBtcAmount(props.row.amount)) }}
          </o-table-column>
        </o-table>
        <div
          v-if="inputsList.length > 0"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="inputsList.length && totalPages > 1"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
      <div v-else-if="activeTab === 'outputs'">
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="outputsCheckedRows"
          class="pagination-table inputs-outputs-table"
          :data="outputsList"
          hoverable
          :is-row-checkable="(row) => checkStateHandler(row, displayOutputsTotal)
            || !Boolean(outputsCheckedRows.find(v => v.address === row.address))"
          @check="toggleOutputsAddressData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="address"
            label="Address"
          >
            <div
              class="link"
              @click="openInNewTabAddress(props.row.address)"
            >
              {{ trancateString(props.row.address, 10) }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="actions"
            label="Actions"
          >
            <div class="link">
              <AddressTxActions
                :address="props.row.address"
              />
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            {{ toComaSeparate(formatBtcAmount(props.row.amount)) }}
          </o-table-column>
        </o-table>
        <div
          v-if="outputsList.length > 0"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="outputsList.length && outputsTotalPages > 1"
            class="stat-pagination"
            :current.sync="outputsCurrentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="outputsCount"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex'
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { formatFunds } from "@/utils/report-data-formatter";
import {formatShare, formatter} from "@/utils/sourcesFormatter";
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { featureAccess } from "@/utils/accesses";
// Components
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import GlPie from '@/components/charts/gl-pie-chart';
import StatusBlock from "@/pages/report/components/StatusBlock";
import AddressTxActions from './components/addressTxActions';
// Libs
import _ from "lodash";

export default {
  name: 'TransactionInfo',
  components: {
    GlPie,
    GlLoader,
    InfoBlock,
    GlMenuItem,
    StatusBlock,
    AddressTxActions
  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    searchMode: {
      type: String,
      default: 'tx',
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      pieData: [],
      track: true,
      calcData: [],
      totalFunds: 0,
      sectionData: null,
      calcLoading: true,
      allDataSource: [],
      trackByField: 'share',
      addressRiskPercent: 0,
      transactionData: {},
      addressData: {},
      transactionInfoData: {},
      transactionInfoDataIO: {},
      inputsList: [],
      outputsList: [],
      checkedRows: [],
      outputsCheckedRows: [],
      isTxDataLoading: false,
      isTxInfoLoading: false,
      isAddressDataLoading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      outputsCurrentPage: 1,
      outputsPerPage: 10,
      outputsTotalPages: 1,
      outputsCount: 1,
      outputsTotal: 0,
      inputsTotal: 0,
      displayInputsTotal: [],
      displayOutputsTotal: [],
      activeTab: 'inputs',
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    elementLabel() {
      return (this.selectedElement && this.selectedElement.type || '').replace('tx', 'Transaction Hash')
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  async mounted() {
    this.formattedInputsCheckedTotal()
    this.loadTransactionData()
    this.loadTransactionInfo()
    this.loadTransactionDataIO()
    if (this.selectedElement.data.source) {
      await this.loadAddressData()
    }
  },
  methods: {
    ...mapActions('analytics', [
      'getTransactionInfo',
      'getTransactionInfoIO',
      'getTransactionData',
      'getAddressData',
      'getTransactionRisk'
    ]),
    formatter,
    formatDate,
    formatShare,
    formatFunds,
    featureAccess,
    trancateString,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    findColorByTypeScore,
    toFullReport(tx) {
      const { href } = this.$router.resolve({ name: 'report', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    loadCalculation() {
      this.calcLoading = true
      this.getTransactionRisk(this.selectedElement.value).then(({data, success}) => {
        if (success) {
          this.calcData = data.sources
          this.totalFunds = data.totalFunds

          this.calkDataFormatter()
          this.calcLoading = false
        } else {
          this.$toasted.global.error({message: `${data.message}`})
          this.calcLoading = false
        }
      }).catch(() => {
        this.calcLoading = false
      })
    },
    calkDataFormatter() {
      const groupedByType = formatter(this.calcData, 'funds.type')

      this.addressRiskPercent = 0

      groupedByType.forEach(item => {
        if (item.funds.score >= 55) {
          this.addressRiskPercent += item.share
        }
      })

      this.allDataSource = groupedByType.map(item => ({
        ...item,
        owners: this.calcData
            .filter((v) => v.funds.type === item.funds.type)
            .map((v) => ({
              ...v,
              name: v.owner,
            })),
        funds: {
          ...item.funds,
          default: item.funds.default || false
        },
        key: item.entity,
        name: `${this.trackByField === 'share' ? `${formatShare(groupedByType.length === 1 ? 1 : item.share)}` : this.formatBtcAmount(item[this.trackByField], true, 3)} ${this.capitalizeFirstLetter(item.funds.type)}`,
        isOpen: false,
        value: item.share,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item[this.trackByField]) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
      }))

      this.combineSourcesOwners()
      this.sortedPieData()
    },
    handlePieHover($event) {
      this.sectionData = $event.data

      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }
    },
    handlePieClick({data}) {
      const typeIndex = this.allDataSource.findIndex(item => item.type === data.type)
      this.toggleTypeData(typeIndex)
    },
    sortedPieData() {
      this.pieData = _.cloneDeep(this.allDataSource)
      this.pieData = this.pieData.sort(({ funds: a }, { funds: b }) =>
          a.default - b.default || b.score - a.score
      )
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.allDataSource = this.allDataSource.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)} ${this.capitalizeFirstLetter(item.funds.type)}`,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
      }))

      this.sortedPieData()
    },
    combineSourcesOwners() {
      this.allDataSource.map((item) => {
        item.owners = formatter(item.owners, 'name')
      })
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address } })
      window.open(href, '_blank')
    },
    formattedInputsCheckedTotal() {
      const a = []
      const b = []
      this.displayInputsTotal = []
      this.displayOutputsTotal = []
      this.cytoscape.cy.edges(`[txHash="${this.selectedElement.value}"]`).forEach(v => {
        a.push(v.data('source'))
      })

      this.cytoscape.cy.edges(`[txHash="${this.selectedElement.value}"]`).forEach(v => {
        b.push(v.data('target'))
      })

      this.displayInputsTotal = a.filter((v, i) => a.indexOf(v) === i)
      this.displayOutputsTotal = b.filter((v, i) => b.indexOf(v) === i)
    },
    async updateData() {
      this.currentPage = 1
      this.outputsCurrentPage = 1
      await this.loadTransactionData()
      await this.loadTransactionInfo()
      if (this.selectedElement.data.source) {
        await this.loadAddressData()
      }
      await this.loadTransactionDataIO()
      await this.formattedInputsCheckedTotal()
      await this.loadCalculation()
    },
    checkStateHandler(rowData, displayData) {
      return !((displayData.length === 1 && displayData.findIndex(v => v === rowData.address) !== -1)
        || this.cytoscape.isCircleTx(rowData.address)
        || this.cytoscape.nodeHasManyTxsHash(rowData.address))
    },
    loadTransactionData() {
      if (this.selectedElement.data.source) {
        this.isTxDataLoading = true
        this.getTransactionData(this.selectedElement.data.source)
          .then(({ data }) => {
            this.transactionData = data
          })
          .finally(() => {
            this.isTxDataLoading = false
          })
      }
    },
    checkingRows() {
      this.inputsList.map((item) => {
        if (this.cytoscape.searchElements(item.address, false, false).length > 0
          && this.cytoscape.checkConnectAddressByHash(item.address, this.selectedElement.value)) {
          this.checkedRows.push(item)
        }
      })

      this.outputsList.map((item) => {
        if (this.cytoscape.searchElements(item.address, false, false).length > 0
            && this.cytoscape.checkConnectAddressByHash(item.address, this.selectedElement.value)) {
          this.outputsCheckedRows.push(item)
        }
      })
    },
    changeTab(tab) {
      this.currentPage = 1
      this.outputsCurrentPage = 1
      this.activeTab = tab

      if (tab === 'sof') {
        this.loadCalculation()
      }

      this.loadTransactionInfo()
      this.loadTransactionDataIO()
    },
    toggleOutputsAddressData(val) {
      const selectedEl = val[val.length - 1]
      // this.outputsCheckedRows = this.outputsCheckedRows.filter((v,i,a)=>a.findIndex(t=>(t.address===v.address))===i)
      const diffItem = _.difference(this.outputsCheckedRows, val)
      const isAdd = val.length > this.outputsCheckedRows.length
      const supportTxData = {
        ...this.transactionInfoDataIO,
        inputsAmount: this.transactionInfoDataIO.inputsAmount,
        outputsAmount: this.transactionInfoDataIO.outputsAmount,
        tx_hash: this.transactionInfoDataIO.tx_hash
      }

      if (isAdd) {
        this.cytoscape.addOutput(selectedEl, this.selectedElement.value, supportTxData)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.removeInputOrOutput(ele.address)
          })
        }
      }

      this.cytoscape.checkFulledDisplayingTX(this.selectedElement.value)

      this.formattedInputsCheckedTotal()
    },
    toggleAddressData(val) {
      const selectedEl = val[val.length - 1]
      const diffItem = _.difference(this.checkedRows, val)
      const isAdd = val.length > this.checkedRows.length
      const supportTxData = {
        ...this.transactionInfoDataIO,
        inputsAmount: this.transactionInfoDataIO.inputsAmount,
        outputsAmount: this.transactionInfoDataIO.outputsAmount,
        tx_hash: this.transactionInfoDataIO.tx_hash
      }

      if (isAdd) {
          this.cytoscape.addInput(selectedEl, this.selectedElement.value, supportTxData)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.removeInputOrOutput(ele.address)
          })
        }

        this.cytoscape.checkFulledDisplayingTX(this.selectedElement.value)
      }

      this.checkingRows()

      this.formattedInputsCheckedTotal()
    },
    pageChange(event) {
      if (this.activeTab === 'inputs') {
        this.currentPage = event
      } else {
        this.outputsCurrentPage = event
      }
      this.loadTransactionDataIO()
    },
    formattedSendData() {
      if (this.activeTab === 'inputs') {
        return {
          txHash: this.selectedElement.value,
          inputsPerPage: this.perPage,
          inputsSkip: (this.currentPage - 1) * this.perPage,
        }
      } else {
        return {
          txHash: this.selectedElement.value,
          outputsPerPage: this.outputsPerPage,
          outputsSkip: (this.outputsCurrentPage - 1) * this.outputsPerPage,
        }
      }
    },
    loadTransactionDataIO() {
      this.isTxInfoLoading = true
      const sendParams = this.formattedSendData()
      this.getTransactionInfo(sendParams)
          .then(({ data }) => {
            this.inputsList = []
            this.outputsList = []
            this.transactionInfoDataIO = data
            this.inputsList = data.inputs
            this.total = data.inputsCount
            this.totalPages = Math.ceil(this.total / this.perPage)
            this.outputsList = data.outputs
            this.outputsCount = data.outputsCount
            this.outputsTotal = data.totalOutputs
            this.inputsTotal = data.totalInputs
            this.outputsTotalPages = Math.ceil(this.outputsCount / this.outputsPerPage)
            this.checkingRows()
          })
          .finally(() => {
            this.isTxInfoLoading = false
          })
    },
    loadTransactionInfo() {
      this.isTxInfoLoading = true
      const sendParams = this.formattedSendData()
      this.getTransactionInfo(sendParams)
        .then(({ data }) => {
          this.transactionInfoData = data
        })
        .finally(() => {
          this.isTxInfoLoading = false
        })
    },
    loadAddressData() {
      this.isAddressDataLoading = true
      this.getAddressData(this.selectedElement.data.source)
        .then(({ data }) => {
          this.addressData = data
        })
        .finally(() => {
          this.isAddressDataLoading = false
        })
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.tabs {
  display: flex;
  position: relative;
  justify-content: space-around;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid var(--pale-grey);
}

.tabs-item {
  display: inline-flex;
  padding: 12px 24px;
  color: var(--dark-grey-6-e);
  position: relative;
  cursor: pointer;
}

.tabs-item--active {
  font-weight: 600;
  color: #1b1f3b;
}

.tabs-item--active::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--soft-blue);
  transition: all 0.5s;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 155px;
}

.risk-score-label {
  font-size: 14px;
}

.sub-types-owner {
  text-align: left;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.sofPie {
  width: auto;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  transform: translateX(-100%);
}

.sub-types-amount {
  white-space: nowrap;
  display: block;
  min-width: 140px;
}

.switch__wrap input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch__wrap label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 10px;
  background: #3030D628;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch__wrap label:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: var(--reflex-bluet);
  border-radius: 90px;
  transition: 0.3s;
}

.switch__wrap input:checked + label {
  background: #3030D628;
}

.switch__wrap input:checked + label:after {
  left: 100%;
  transform: translateX(-100%);
}

.switch__wrap label:active:after {
  width: 50px;
}

.switch__wrap {
  margin-bottom: 3px;
}

.aml-detected-list {
  margin-bottom: 100px;
}

@keyframes skeleton-animation
{

  0%
  {
    right: 100%;
  }

  50%
  {
    right: 0;
  }

  100%
  {
    right: 100%;
  }

}
</style>
