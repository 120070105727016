<template>
  <div class="fs-14">
    <div class="sidebar__section px-3">
      <p class="sidebar__analytics-label">
        TX hash
      </p>
      <p
        class="sidebar__analytics-value flex align-center"
        :title="selectedElement.value"
      >
        <span>
          {{ selectedElement.data.txHash }}
        </span>
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.data.txHash)"
        />
      </p>
    </div>
    <div class="tabs mb-4">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <div
          v-if="tab.visible"
          class="tabs-item"
          :class="[{'tabs-item--active': activeTab === tab.name}]"
          @click="changeTab(tab.name)"
        >
          <div>
            {{ tab.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'sof'"
      class="pa-3 pt-0 relative"
    >
      <div v-if="calcLoading">
        <gl-loader class="table__loader" />
      </div>
      <div v-else>
        <div class="flex space-between align-flex-end">
          <div class="column">
            <div class="flex space-between mb-2 align-center">
              <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                filter by token
              </div>
            </div>
            <vSelect
              v-model="token"
              class="tokens-list mr-1"
              :clearable="false"
              :disabled="isTxDataLoading"
              label="symbol"
              :options="tokensList"
              placeholder="Select Token"
              @input="handleSelectToken"
            >
              <template v-slot:selected-option="{ symbol, icon }">
                <GlImg
                  v-if="icon"
                  class="mr-1"
                  :src-val="icon"
                />
                {{ symbol }}
              </template>
              <template v-slot:option="option">
                <GlImg
                  v-if="option.icon"
                  class="mr-1"
                  :src-val="option.icon"
                />
                {{ option.symbol }}
              </template>
            </vSelect>
          </div>
          <div class="flex fs-14 align-end mr-3">
            Amount
            <div class="px-1 switch__wrap">
              <input
                id="switch"
                v-model="trackActivator"
                type="checkbox"
                @click="trackBy()"
              >
              <label for="switch">Toggle</label>
            </div>
            %
          </div>
        </div>
        <div
          v-if="allDataSource.length > 0"
          class="flex align-center justify-center relative"
        >
          <gl-pie-chart
            ref="pie"
            class="relative aml-detected-list"
            :data-source="allDataSource"
            :height="470"
            :width="'100%'"
            @mouseout="sectionData = null"
            @move="handlePieHover"
          >
            <template slot="tooltip">
              <transition name="fade">
                <div
                  v-if="sectionData"
                  id="sourcePie"
                  class="testPie"
                >
                  <div
                    v-for="(owner, index) in sectionData.owners"
                    :key="index"
                    :class="{'mb-2': index < sectionData.owners.length - 1}"
                  >
                    <div class="flex align-center">
                      <div class="sub-types-amount">
                        <span class="">
                          {{ trackByField === 'share'
                            ? formatShare(allDataSource.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                            : owner.formattedAmount }}
                        </span>
                      </div>
                      <div
                        v-tooltip.top="capitalizeFirstLetter(owner.name)"
                        class="ml-3 ellipsis flex flex-start flex flex-1 sub-types-owner"
                      >
                        {{ capitalizeFirstLetter(owner.name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
          </gl-pie-chart>
          <div class="risk-score-wrap">
            <strong class="risk-score-label">
              Risk Score
            </strong>
            <div
              class="risk-score-value"
              :style="`color: ${findColorByTypeScore(totalFunds)}`"
            >
              {{ formatFunds(totalFunds, false) }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex justify-center mb-3"
        >
          No data here yet
        </div>
        <div class="gl-form__actions">
          <button
            class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
            @click="toFullReport(selectedElement.data.txHash)"
          >
            get full report
          </button>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'general'">
      <div class="stat px-3">
        <div class="stat-tag full">
          <InfoBlock
            v-if="selectedElement.data.additionalData.method_name"
            class="mr-2"
            label="Method"
            :value="selectedElement.data.additionalData.method_name"
          />
          <InfoBlock
            v-if="selectedElement.data.amount"
            class="mr-2"
            label="Total amount"
            :value="toComaSeparate(formatBtcAmount(selectedElement.data.amount,true, selectedElement.data.searchType, selectedElement.data.contract))"
          />
          <InfoBlock
            label="Timestamp"
            :value="formatDate(selectedElement.data.additionalData.timestamp * 1000, 'dd.MM.yyyy HH:mm')"
          />
          <InfoBlock
            label="Block"
            :value="selectedElement.data.additionalData.blockHeight"
          />
        </div>
      </div>
      <div class="px-3 mb-4">
        <InfoBlock
          class="mb-4"
          full
          label="From"
          :value="selectedElement.data.sourceAddress"
        />
        <InfoBlock
          full
          label="To"
          :value="selectedElement.data.targetAddress"
        />
      </div>
      <div
        v-if="cytoscape.hasSimpleViewElement(selectedElement.data.txHash)"
        class="px-3 mb-4"
      >
        <gl-checkbox
          v-model="simpleView"
          class="mb-2"
          label="Show internal transfers "
          @input="handleSimpleView"
        />
        <gl-checkbox
          v-model="advancedView"
          label="Show smart-contract calls"
          @input="handleFullView"
        />
      </div>
      <div v-if="txsList.length > 0 && txsList.length !== total">
        <StatusBlock
          v-if="total > 10"
          class="mb-2 px-3"
          label="Limited number of txs is displayed"
        />
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          class="pagination-table inputs-outputs-table px-3"
          :data="txsList"
          hoverable
          :loading="isTxDataLoading"
          @check="toggleTxData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="from"
            label="from"
          >
            <div
              v-if="props.row.from"
              class="link"
              @click="openInNewTabAddress(props.row.from)"
            >
              {{ trancateString(props.row.from, 8) }}
            </div>
            <div v-else>
              --
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="to"
            label="to"
          >
            <div
              v-if="props.row.to"
              class="link"
              @click="openInNewTabAddress(props.row.to)"
            >
              {{ trancateString(props.row.to, 8) }}
            </div>
            <div v-else>
              --
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            {{
              props.row.value || props.row.valueEth
                ? toComaSeparate(formatBtcAmount(getDecimalVal(props.row, props.row.value || props.row.valueEth), true, 'eth', props.row.contractData && props.row.contractData.symbol))
                : '--'
            }}
          </o-table-column>
        </o-table>
        <div
          v-if="txsList.length > 0 && txsList.length !== total"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="txsList.length"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="limit"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapState} from 'vuex'
// Utils
import { featureAccess } from "@/utils/accesses";
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatFunds } from "@/utils/report-data-formatter";
import { formatBtcAmount, getDecimalVal } from '@/utils/format-btc-amount';
import {formatShare, formatter} from "@/utils/sourcesFormatter";
import {findColorByTypeScore, tokensSorting} from "@/utils/cytoskape-ui-rules";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
// Components
import GlLoader from "@/components/gl-loader";
import GlCheckbox from "@/components/gl-checkbox";
import GlMenuItem from '@/components/gl-menu-item';
import InfoBlock from '@/components/gl-info-block';
import GlPieChart from "@/components/charts/gl-pie-chart";
import StatusBlock from "@/pages/report/components/StatusBlock.vue";
import GlImg from "@/components/gl-img.vue";
import vSelect from "vue-select";

// Libs
import _ from "lodash";
import { ethIcon } from "@/assets/static/eth-base-64-icon";

export default {
  name: 'TransactionInfo',
  components: {
    vSelect,
    GlImg,
    StatusBlock,
    GlLoader,
    InfoBlock,
    GlCheckbox,
    GlMenuItem,
    GlPieChart,
  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    searchMode: {
      type: String,
      default: 'tx',
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      tokensList: [],
      simpleView: false,
      advancedView: false,
      trackByField: 'share',
      trackActivator: 'share',
      calcLoading: false,
      totalFunds: 0,
      sectionData: null,
      allDataSource: [],
      transactionData: {},
      addressData: {},
      transactionInfoData: {},
      transactionInfoDataIO: {},
      prevNextInfo: null,
      txsList: [],
      limit: 10,
      currentPage: 1,
      total: 1,
      checkedRows: [],
      outputsCheckedRows: [],
      isTxDataLoading: false,
      isTxInfoLoading: false,
      isAddressDataLoading: false,
      displayInputsTotal: [],
      displayOutputsTotal: [],
      activeTab: 'general',
      token: { address: '', symbol: 'ETH' },
      tabs: [{
        name: 'general',
        title: 'General',
        visible: true
      }, {
        name: 'sof',
        title: 'Source Of Funds',
        visible: featureAccess('REPORT')
      }],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  mounted() {
    if (!this.token.address) {
      this.token.symbol = this.coinData.label
      this.token.icon = ethIcon
    }
    this.updateData()
  },
  methods: {
    formatter,
    formatDate,
    formatFunds,
    formatShare,
    tokensSorting,
    getDecimalVal,
    featureAccess,
    toComaSeparate,
    trancateString,
    formatBtcAmount,
    findColorByTypeScore,
    capitalizeFirstLetter,
    toFullReport(val) {
      const { href } = this.$router.resolve({ name: 'report', query: { tx: val, type: this.coinType }})
      window.open(href, '_blank')
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    handleSelectToken() {
      if (this.activeTab === 'sof') {
        this.loadCalcData(false)
      }
    },
    toggleTxData(data) {
      const selectedEl = data[data.length - 1]
      const diffItem = _.difference(this.checkedRows, data)
      const isAdd = data.length > this.checkedRows.length

      if (isAdd) {
        this.cytoscape.visualizeEthData(this.selectedElement.data.txHash, [selectedEl], {}, this.prevNextInfo)
        this.cytoscape.checkFullTxsListOnGraph(this.selectedElement.data.txHash)
      } else if (diffItem.length > 0) {
        diffItem.forEach(ele => {
          this.cytoscape.removeTxByID(ele._id)
          this.cytoscape.checkFullTxsListOnGraph(this.selectedElement.data.txHash)
        })
      }

    },
    updateData() {
      this.simpleView = !this.selectedElement.data.simpleView
      this.activeTab = 'general'
      this.advancedView = Boolean(this.selectedElement.data.viewAdditionalData)
      this.loadTxsList()
    },
    ...mapActions('analytics', [
      'getTransactionInfo',
      'getTransactionData',
      'getAddressData',
      'getTxEthScore',
      'getTxEthData'
    ]),
    pageChange(page) {
      this.currentPage = page
      this.loadTxsList()
      this.checkedRows = []
    },
    loadTxsList() {
      this.isTxDataLoading = true
      this.getTxEthData({
        tx: this.selectedElement.data.txHash,
        limit: this.limit,
        page: this.currentPage,
        address: new RegExp(this.coinData?.addressRegex).test(this.cytoscape.search) ? this.cytoscape.search : undefined,
        only: 'list'
      }).then(({ data, success }) => {
        if (success) {
          this.txsList = data.txs
          const contractsData = _.uniqBy(data.txs.filter(tx => tx.contractData && tx.contractData.name).map(tx => tx.contractData), 'address')
          this.tokensList = this.tokensSorting(contractsData)
          this.tokensList.unshift({ address: '', symbol: this.coinData.label, icon: ethIcon })
          this.prevNextInfo = data.prevNextInfo
          this.total = data.total
          this.preCheckingRows()
        }
      }).finally(() => {
        this.isTxDataLoading = false
      })
    },
    preCheckingRows() {
      this.checkedRows = this.txsList.filter(el => this.cytoscape.cy.edges().find(edge => edge.data("_id") === el._id))
    },
    handlePieHover($event) {
      this.sectionData = $event.data

      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.allDataSource = this.allDataSource.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount} ${this.capitalizeFirstLetter(item.funds.type)}`,
        tooltip: `${this.capitalizeFirstLetter(item.funds.type)} ${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount}`,
      }))

      this.sortedPieData()
    },
    sortedPieData() {
      this.allDataSource = this.allDataSource.sort(({ funds: a }, { funds: b }) =>
          a.default - b.default || b.score - a.score
      )
    },
    loadCalcData(needSetAvailableToken = true) {
      this.calcLoading = true
      this.getTxEthScore({
        tx: this.selectedElement.data.txHash,
        token: this.token.address,
        tokenTicker: this.token.symbol
      }).then(({ data: { sources, totalFunds, currencies }}) => {
        this.totalFunds = totalFunds || 0

        if (!this.token.address && !sources?.length && this.tokensList.length && needSetAvailableToken) {
          this.token = this.tokensSorting(this.tokensList)[0]
          this.loadCalcData(false)
        }

        const groupedSourcesByType = formatter(sources, 'funds.type')

        this.allDataSource = groupedSourcesByType.map(item => {
          const localCurrency = currencies[item.currency] || {}
          return {
            ...item,
            owners: sources
              .filter((v) => v.funds.type === item.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
                formattedAmount: this.formatBtcAmount(v.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.key, localCurrency.currency),
              })),
            funds: {
              ...item.funds,
              default: item.funds.default || false
            },
            formattedAmount: this.formatBtcAmount(item.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.key, localCurrency.currency),
            key: item.funds.type,
            name: `${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount} ${this.capitalizeFirstLetter(item.funds.type)}`,
            tooltip: `${item.funds.type} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          }})

        this.combineSourcesOwners()
        this.sortedPieData()
      }).finally(() => {
        this.calcLoading = false
      })
    },
    combineSourcesOwners() {
      this.allDataSource.map((item) => {
        item.owners = formatter(item.owners, 'name')
      })
    },
    changeTab(tab) {
      this.activeTab = tab
      switch (tab) {
        case 'sof':
          this.loadCalcData();
          break;
        case 'general':
          this.updateData()
          break;
      }
    },
    handleSimpleView() {
      this.cytoscape.toggleSimpleView(!this.simpleView, this.selectedElement.data.txHash)
    },
    handleFullView() {
      this.cytoscape.toggleFullView(this.advancedView, this.selectedElement.data.txHash)
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid var(--pale-grey);
}

.tabs-item {
  display: inline-block;
  padding: 12px 24px;
  color: var(--dark-grey-6-e);
  position: relative;
  cursor: pointer;
}

.tabs-item--active {
  font-weight: 600;
  color: #1b1f3b;
}

.tabs-item--active::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--soft-blue);
  transition: all 0.5s;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 155px;
}

.risk-score-label {
  font-size: 14px;
}

.testPie {
  /*min-width: 400px;*/
  width: auto;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}
</style>
