<template>
  <div class="table-wrap table-wrap__unoverflow gl-table-unshadow px-3">
    <o-table
      v-if="data.type"
      class="mb-4"
      :data="[{score: data.type && data.type.score || null, name: data.type && data.type.name || null, metadata: data.description || null}]"
      hoverable
      :loading="loading"
    >
      <o-table-column
        v-slot="props"
        label="Cluster owner type"
        width="220px"
      >
        <GlTag
          :score="props.row.score"
          :tag="capitalizeFirstLetter(props.row.name)"
        />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="score"
        label="Metadata"
      >
        <div class="flex align-center tag_prent">
          <a
            v-if="props.row.metadata && isValidURL(props.row.metadata)"
            class="link ellipsis"
            :href="props.row.metadata"
            style="max-width: 350px"
            target="_blank"
          >{{ props.row.metadata }}</a>
          <div
            v-else
            class="ellipsis"
            style="max-width: 350px"
          >
            {{ props.row.metadata || '-' }}
          </div>
          <div class="tag_child">
            <gl-menu-item
              class="sidebar__history-copy"
              icon="copy"
              :icon-height="24"
              :icon-width="24"
              @click="copy(props.row.metadata)"
            />
          </div>
        </div>
      </o-table-column>
    </o-table>
    <o-table
      :class="{ 'o-table-has-pagination': tagsData.length && totalPages > 1 }"
      :data="tagsData"
      hoverable
      :loading="loading"
    >
      <o-table-column
        v-slot="props"
        field="score"
        label="Tag"
        width="220px"
      >
        <GlTag
          :score="props.row.score"
          :tag="capitalizeFirstLetter(props.row.name)"
        />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="score"
        label="Metadata"
      >
        <div class="flex align-center tag_prent">
          <a
            v-if="props.row.metadata && isValidURL(props.row.metadata)"
            class="link ellipsis"
            :href="props.row.metadata"
            style="max-width: 350px"
            target="_blank"
          >{{ props.row.metadata }}</a>
          <div
            v-else
            class="ellipsis"
            style="max-width: 350px"
          >
            {{ props.row.metadata || '-' }}
          </div>
          <div class="tag_child">
            <gl-menu-item
              class="sidebar__history-copy"
              icon="copy"
              :icon-height="24"
              :icon-width="24"
              @click="copy(props.row.metadata)"
            />
          </div>
        </div>
      </o-table-column>
      <template #empty>
        <div
          v-if="loading"
          class="flex align-center justify-center"
        >
          <gl-loader />
        </div>
      </template>
    </o-table>
    <div
      v-if="tagsData.length === 0 && !loading"
      class="text-center mt-3"
    >
      No cluster tags identified
    </div>
    <div
      v-if="tagsData.length > 0"
      class="flex flex-end pa-1"
    >
      <!--      <div class="flex align-center pa-3">-->
      <!--        <div class="mr-2 fs-14 bold">-->
      <!--          Rows per page:-->
      <!--        </div>-->
      <!--        <vSelect-->
      <!--          v-model="perPage"-->
      <!--          class="stat-select__pagination mr-1"-->
      <!--          :clearable="false"-->
      <!--          :options="pagesOptions"-->
      <!--          @input="countChange"-->
      <!--        />-->
      <!--      </div>-->
      <o-pagination
        v-if="tagsData.length"
        class="stat-pagination"
        :current.sync="currentPage"
        order="right"
        :per-page="perPage"
        simple
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
  </div>
</template>

<script>
//Vuex
import {mapActions} from "vuex";
// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import vSelect from 'vue-select'
import GlTag from '@/components/gl-tag'
// Utils
import { capitalizeFirstLetter, isValidURL } from "@/utils/text-formatter";

export default {
  name: 'ClusterTagsTable',
  components: {
    GlMenuItem,
    GlTag,
    GlIcon,
    vSelect,
    GlLoader
  },
  props: {
    cluster: {
      type: String,
      default: '',
    },
    data: {
      type: [Object, Array],
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      pagesOptions: [5, 10,20, 50],
      currentPage: 1,
      perPage: 5,
      totalPages: 1,
      total: 1,
      score: 0,
      tagsData: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('analytics', ['getClusterTags']),
    isValidURL,
    capitalizeFirstLetter,
    async copy(value) {
      await navigator.clipboard.writeText(value)
      this.$toasted.global.success({ message: 'Copied!' })
    },
    formattedSendData() {
      return {
        count: this.perPage,
        cluster: this.cluster || this.data.cluster,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    async loadData() {
      this.loading = true
      const sendParams = this.formattedSendData()

      if (!sendParams.cluster) {
        this.tagsData = []
        this.total = 0
        this.loading = false
        return
      }

      const { data: { total, tags, meta } } = await this.getClusterTags(sendParams)
      this.tagsData = tags.map(tag => ({
        ...tag,
        name: capitalizeFirstLetter(tag.name),
        metadata: meta.find(item => item.tagId === tag._id)
            ? meta.find(item => item.tagId === tag._id).metadata
            : '',
      }))
      this.total = total
      this.totalPages = Math.ceil(this.total / this.perPage)
      this.loading = false
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>

</style>
