<template>
  <div class="toolbar-right__wrap">
    <div
      class="toolbar toolbar__controls toolbar__controls--right column"
      :class="{'toolbar__controls--collapse': !isShrunk}"
    >
      <gl-menu-item
        v-tooltip.left="'Add tx hash / wallet address to graph'"
        class="mb-4"
        icon="add"
        :icon-height="24"
        :icon-width="24"
        @click="add"
      />
      <gl-menu-item
        v-tooltip.left="'Find route in graph'"
        class="mb-4"
        icon="find-route"
        :icon-height="24"
        :icon-width="24"
        @click="findRoute"
      />
      <gl-menu-item
        v-tooltip.left="'Undo last request'"
        class="mb-4"
        icon="undo"
        :icon-height="24"
        :icon-width="24"
        @click="undoLast"
      />
      <gl-menu-item
        v-tooltip.left="'Redo last request'"
        class="mb-4"
        icon="redo"
        :icon-height="24"
        :icon-width="24"
        @click="redoLast"
      />
      <gl-menu-item
        v-tooltip.left="'Zoom in'"
        class="mb-4"
        @click="changeZoom('in')"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="zoom-in"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="'Zoom out'"
        class="mb-4"
        @click="changeZoom('out')"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="zoom-out"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="'Increase nodes spacing'"
        class="mb-4"
        @click="changeNodeSpacing(45)"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="external-link"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="'Decrease node spacing'"
        class="mb-4"
        @click="changeNodeSpacing(10)"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="external-link-revert"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="'Lock selected nodes'"
        class="mb-4"
        icon="lock"
        :icon-height="24"
        :icon-width="24"
        @click="lockNodes"
      />
      <gl-menu-item
        v-tooltip.left="'Unlock selected nodes'"
        class="mb-4"
        icon="unlock"
        :icon-height="24"
        :icon-width="24"
        @click="unlockNodes"
      />
      <gl-menu-item
        v-tooltip.left="'Hide nodes'"
        class="mb-4"
        icon="hide"
        :icon-height="24"
        :icon-width="24"
        @click="hideNodes"
      />
      <gl-menu-item
        v-tooltip.left="'Show hidden nodes'"
        class="mb-4"
        icon="unhide"
        :icon-height="24"
        :icon-width="24"
        @click="showHidden"
      />
    </div>
    <div
      class="right"
      :class="{'toolbar-right__wrap--expand': isShrunk}"
    >
      <div class="toolbar-right__header pointer">
        <div
          v-show="isShrunk"
          class="pa-3"
        >
          {{ title }}
        </div>
        <div
          class="toolbar-right__header-collapse-btn relative"
          @click="handleShrunk"
        >
          <gl-icon
            :height="24"
            light
            :name="!isShrunk ? 'expand' : 'collapse'"
            :width="24"
          />
          <div
            v-if="!isShrunk && Object.keys(selectedElement).length !== 0"
            class="has-data__mark"
          />
        </div>
      </div>
      <div v-show="isShrunk">
        <div
          v-if="loading"
          class="flex justify-center"
        >
          <gl-loader />
        </div>
        <div
          v-else-if="Object.keys(selectedElement).length === 0"
          class="gl-analytics-dropdown__list-item"
        >
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/node-preview.png"
              style="width: 150px"
            >
            <div class="empty-analytics__count">
              1
            </div>
            <div class="empty-analytics__label">
              Select a node to see the <br> address information
            </div>
          </div>
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/tx-preview.png"
              style="width: 150px"
            >
            <div class="empty-analytics__count">
              2
            </div>
            <div class="empty-analytics__label">
              Select a transaction to see the <br> transaction information
            </div>
          </div>
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/cluster-preview.png"
              style="width: 150px"
            >
            <div class="empty-analytics__count">
              3
            </div>
            <div class="empty-analytics__label">
              Select a cluster to see the <br> cluster information
            </div>
          </div>
        </div>
        <EthAddressInfo
          v-else-if="(selectedElement.data.type === 'eth' || selectedElement.data.type === 'bnb' || selectedElement.data.type === 'trx') && selectedElement.data.nodeData"
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
          @set-eth-transaction="provideEthTx"
        />
        <EthTransactionInfo
          v-else-if="selectedElement.type === 'eth' || selectedElement.type === 'bnb' || selectedElement.type === 'trx'"
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
        />
        <AddressInfo
          v-else-if="selectedElement.type === 'address'"
          :cytoscape="cytoscape"
          data-cy="addressAnalytics"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
          @search-and-hide-elements="provideHide"
          @set-transaction="provide"
          @toggle-calendar="calendarOpen = !calendarOpen"
        />
        <ClusterInfo
          v-else-if="selectedElement.type === 'cluster'"
          data-cy="clusterAnalytics"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
        />
        <TransactionInfo
          v-else
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import AddressInfo from '../analytics-info-blocks/address-info'
import ClusterInfo from '../analytics-info-blocks/cluster-info'
import TransactionInfo from '../analytics-info-blocks/transaction-info'
import EthTransactionInfo from "@/pages/analytics/analytics-info-blocks/eth-transaction-info";
import EthAddressInfo from "@/pages/analytics/analytics-info-blocks/eth-address-info";
import GlLoader from '@/components/gl-loader'

// Vuex
import { mapMutations } from "vuex";

export default {
  components: {
    GlMenuItem,
    GlIcon,
    AddressInfo,
    TransactionInfo,
    EthTransactionInfo,
    EthAddressInfo,
    ClusterInfo,
    GlLoader,
  },
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isShrunk: false
    }
  },
  computed: {
    title() {
      if (Object.keys(this.selectedElement).length === 0) return ''
      if (this.selectedElement?.data?.nodeData?.isContract && this.selectedElement?.data?.nodeData?.hasSymbol) return 'Token Contract Info'
      if (this.selectedElement?.data?.nodeData?.isContract && !this.selectedElement?.data?.nodeData?.hasSymbol) return 'Contract Info'
      if (this.selectedElement.type === 'address' || (this.selectedElement.data && this.selectedElement.data.nodeData)) return 'Address Info'
      if (this.selectedElement.type === 'cluster') {
        return 'Cluster Info'
      } else {
        return 'Transaction Info'
      }
    }
  },
  watch: {
    selectedElement: {
      deep: true,
      handler(prev) {
        this.isShrunk = !!prev.value
        if (this.isShrunk) {
          this.SET_SHRUNK(true)
        }

        const alwaysOpen = JSON.parse((localStorage.getItem('alwaysOpen')))

        if (alwaysOpen !== undefined) {
          this.isShrunk = alwaysOpen
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      SET_STEPPED_STATE: 'analytics/SET_STEPPED_STATE',
      SET_SHRUNK: 'sidebar/SET_SHRUNK'
    }),
    crossing() {
      this.$emit('crossing')
    },
    changeNodeSpacing(val) {
      this.$emit('change-node-spacing', val)
    },
    handleShrunk() {
      this.isShrunk = !this.isShrunk
      localStorage.setItem('alwaysOpen', this.isShrunk)
    },
    provide(val) {
      this.$emit('set-transaction', val)
    },
    provideEthTx(data) {
      this.$emit('add-eth-tx-data', data)
    },
    provideHide(val) {
      this.$emit('search-and-hide-elements', val)
    },
    provideTx(val) {
      this.$emit('add-tx-data', val)
    },
    changeZoom(val) {
      this.$emit('change-zoom', val)
    },
    add() {
      this.$emit('add')
    },
    findRoute() {
      this.$emit('find-route')
    },
    undoLast() {
      this.SET_STEPPED_STATE({ undo: false, redo: true })
      this.$emit('undo')
    },
    redoLast() {
      this.SET_STEPPED_STATE({ undo: true, redo: false })
      this.$emit('redo')
    },
    lockNodes() {
      this.$emit('lock-nodes', ':selected')
    },
    unlockNodes() {
      this.$emit('unlock-nodes', ':selected')
    },
    hideNodes() {
      this.$emit('hide-nodes', ':selected')
    },
    showHidden() {
      this.$emit('show-hidden')
    },
  }
}
</script>

<style>
.toolbar-right__wrap {
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 10;
}

.toolbar__controls--right {
  height: fit-content;
  box-shadow: -3px 0 7px 0 rgba(110, 110, 110, 0.11);
  padding: 10px;
  border-right: 1px solid var(--dark-grey-d-3);
}

.toolbar-right__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.toolbar-right__header-collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
}

.right {
  background: #fff;
  height: 100%;
  width: 56px;
  overflow: auto;
}

.toolbar-right__wrap--expand {
  width: 640px;
}

.toolbar__controls--collapse {
  margin-right: -55px;
}

.has-data__mark {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: var(--reflex-bluet);
  position: absolute;
  top: 15px;
  right: 15px;
}

.empty-analytics {
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
  padding: 60px;
  color: #6e6e6e;
  font-size: 16px;
}

.empty-analytics__img {
  width: 150px;
  margin-right: 48px;
}

.empty-analytics__count {
  display: flex;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
  border: 1px solid #979797;
  width: 25px;
  height: 25px;
  border-radius: 50px;
}
</style>
