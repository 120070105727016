<template>
  <div class="fs-14">
    <h4 class="sidebar__sub-title mb-3 px-3">
      General
    </h4>
    <div class="stat px-3">
      <div class="stat-tag full">
        <div class="stat-item relative">
          <InfoBlock
            label="Cluster id"
            :value="selectedElement.value"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-tooltip.top="'Edit labels'"
            class="sidebar__to-labeling"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(selectedElement.value)"
          />
        </div>
        <InfoBlock
          label="Cluster size"
          :loading="isAddressDataLoading"
          :value="addressData
            && addressData.clusterData
            && addressData.clusterData.clusterSize
            && toComaSeparate(addressData.clusterData.clusterSize)"
        />
        <div class="sidebar__section">
          <p class="sidebar__analytics-label">
            Description
          </p>
          <p
            class="sidebar__analytics-description flex align-center value"
            :title="selectedElement.value"
          >
            {{ addressData && addressData.clusterData && addressData.clusterData.description || '--' }}
          </p>
        </div>
        <InfoBlock
          label="owner"
          :value="(selectedElement
            && selectedElement.data
            && selectedElement.data.clusterData
            && selectedElement.data.clusterData.owner) || 'Not identified'"
        />
        <div class="stat-item sidebar__section sidebar__section--full">
          <div class="key">
            Type
          </div>
          <div class="value">
            <VueSkeletonLoader
              v-if="isAddressDataLoading"
              animation="wave"
              :color="'#bec3d8'"
              :height="20"
              :radius="'2'"
              type="rect"
            />
            <div v-else>
              <GlTag
                v-if="addressData
                  && addressData.clusterData
                  && addressData.clusterData.type
                  && addressData.clusterData.type.name
                  && String(addressData.clusterData.type.name)"
                class="mr-1 mb-1"
                :score="addressData.clusterData.type.score"
                :tag="capitalizeFirstLetter(addressData.clusterData.type.name)"
                :value="addressData.type && addressData.clusterData.type.name"
              />
              <span v-else>--</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <VueSkeletonLoader
          v-if="isAddressDataLoading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div v-else>
          <div v-if="!addressData.clusterData.tags || addressData.clusterData.tags.length === 0" class="bold">--</div>
          <div v-else>
            <GlTag
              v-for="(tag, index) in addressData.clusterData.tags"
              :key="index"
              class="mr-1 mb-1"
              :score="tag.score"
              :tag="capitalizeFirstLetter(tag.name)"
            />
          </div>
        </div>
      </div>
    </div>
    <ClusterAddressesTable
      v-if="selectedElement.data.cluster && String(selectedElement.data.cluster)
        || selectedElement.data.clusterData && selectedElement.data.clusterData.cluster"
      :cluster="selectedElement.data.cluster && String(selectedElement.data.cluster)
        || selectedElement.data.clusterData && selectedElement.data.clusterData.cluster"
      @add-tx-data="$emit('add-tx-data', $event)"
    />
  </div>
</template>

<script>
// Utils
import { toComaSeparate } from '@/utils/formatNumber'
import { capitalizeFirstLetter } from "@/utils/text-formatter";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Components
import VueSkeletonLoader from 'skeleton-loader-vue';
import InfoBlock from '@/components/gl-info-block'
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'
import GlTag from '@/components/gl-tag'
import ClusterAddressesTable from './components/clusterAddressesTable'

export default {
  name: 'ClusterInfo',
  components: { InfoBlock, GlIcon,GlMenuItem, VueSkeletonLoader, GlTag, ClusterAddressesTable  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      addressData: {},
      isAddressDataLoading: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    elementLabel() {
      return (this.selectedElement?.type || '').replace('tx', 'Transaction Hash')
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  mounted() {
    this.updateData();
  },
  methods: {
    toComaSeparate,
    capitalizeFirstLetter,
    ...mapActions('analytics', ['getAddressData', 'getTransactionData', 'getTxStats']),
    async updateData() {
      if (this.selectedElement.data.address) {
        await this.loadAddressData(this.selectedElement.data.address)
      } else {
        await this.setDefaultData()
      }
    },
    setDefaultData() {
      this.addressData = {}
    },
    toLabeling(val) {
      this.$router.push({ name: 'labeling', query: { cluster: val, tab: 'labeling' } })
    },
    loadAddressData(address) {
      this.isAddressDataLoading = true
      this.getAddressData(address)
          .then(({ data }) => {
            this.addressData = data;
          })
          .finally(() => {
            this.isAddressDataLoading = false
          })
    },
  },
}
</script>

<style scoped>
.sidebar__to-labeling {
  position: absolute;
  top: 17px;
  left: 80px;
}

.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 50%;
  word-break: break-word;
  margin-bottom: 20px;
}
.value {
  font-weight: 500;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}
</style>
